.modal-user {
    width: 424px;
}

.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.message {
    padding: 60px 0 28px;

    p {
        margin-top: 4px;
        font-size: 16px;
        color: #5D615D;
    }
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.cancel-button {
    border: none;
    background: none;
    padding: 14px 40px;

    span {
        font-size: 10px;
        font-weight: bold;
        color: #5D615D;
        text-decoration: underline;
    }

    &:hover {
        cursor: pointer;
    }
}