.modal-user {
    width: 424px;
}

.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.form-wrapper {
    padding: 64px 0 8px;
}

.fields-wrapper {
    margin-bottom: 20px;
    width: 344px;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.delete-button {
    border: none;
    background: none;
    padding: 14px 40px;

    span {
        font-size: 10px;
        font-weight: bold;
        color: #5D615D;
        text-decoration: underline;
    }

    &:hover {
        cursor: pointer;
    }
}