@import '../../assets/scss/main.scss';


.input-search-customer {
	border: 1px solid #d1d1d1;
	border-radius: 4px;

	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		padding: 16px;
	}
}

.search-input {
	@extend .input-primary;

	input {
		color: #d1d1d1;
		background-color: #ffffff;

		+ label {
			color: $grey;
			text-transform: uppercase;
		}

		&[disabled] {
			color: #d1d1d1;

			+ label {
				color: $grey;
				text-transform: uppercase;
			}
		}

		&:focus,
		&:valid {
			color: $grey;

			+ label {
				top: 12px;
				left: 16px;
				font-size: 0.625rem;
				color: #CE332E;
			}
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
			box-shadow: 0 0 0 30px white inset !important;
			-webkit-text-fill-color: #979e97 !important;
		}
	}
}
