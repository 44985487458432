.pointsColumnValue {
	color: #5ea715;
}

.label-product-redemption {
	display: flex;
	padding: 10px 8px;
	border-radius: 4px;
	align-items: center;
	background-color: #c9e8aa;

	span {
		width: 100%;
		max-width: 66px;
		font-size: 10px;
		font-weight: 600;
		color: #528c18;
		margin-left: 4px;
		display: inline-block;
		text-transform: uppercase;
	}
}
