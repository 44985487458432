@import '../../assets/scss/main.scss';

.logoutSection {
    margin-top: auto;
    padding: 36px;
    width: 100%;
}

.logoutButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    span {
        @include font-type(1);
        color: #FFFFFF;
    }
}