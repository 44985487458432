@import '../../../assets/scss/main.scss';

.spacer80px {
    margin-bottom: 80px;
}

.customerProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    padding: 40px 24px;
    border-bottom: 1px solid #979E9733;
}

.customerName {
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 8px;
}

.customerNickname {
	@include font-type(2);
    font-size: 10px;
    color: #979E97;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.customerProfileLink {
	@include font-type(2);
    font-size: 10px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
}

.routesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    border-bottom: 1px solid #979E9733;
}

.PointsProgramRoutesSection {
	@include font-type(2);
    font-size: 12px;
    color: #A3A3A3;
    align-self: flex-start;
    margin: 24px 0 0 24px;
}

.routesSectionList {
    padding: 24px;
}

.routesListItem {
    width: 100%;
}

.routeLink div {
	@include font-type(2);
    font-size: 14px;
    color: #FFFFFF;
    padding: 12px;
}

.activeRouteLink div {
    color: #CE332E;
    background-color: #FFFFFF;
    border-radius: 4px;
}

.termsAdhesionLink{
    @include font-type(2);
    font-size: 14px;
    color: #FFFFFF;
    padding: 12px;
    cursor: pointer;
}