
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 0.8s ease-out;
  animation-fill-mode: both;
}


@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-left {
  animation: fadeLeft 0.8s ease-out;
  animation-fill-mode: both;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-right {
  animation: fadeRight 0.8s ease-out;
  animation-fill-mode: both;
}