@import '../../../assets/scss/main.scss';

.placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 107px;

    span {
        color: #CE332E;
        font-size: 14px;
        text-align: center;
        width: 250px;
        margin-top: 16px;
    }
}