@use 'sass:math';
@import '../../../assets/scss/main.scss';

.form-customer {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.form {
		width: 100%;
	}

	@include d(md) {
		padding: 0;
	}

	.form-title {
		font-size: toRem(18px);
		letter-spacing: 0.22px;
		color: #5d615d;
		margin-bottom: 20px;
	}

	.input {
		@extend .input-secondary;
		background: white;
		margin-bottom: 16px;

		input {
			color: #5d615d !important;
		}

		label {
			color: #979e97 !important;
		}
	}

	.double-input-col-1 {
		@extend .col-12;
		@extend .col-md-6;

		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;

		@include d(md) {
			padding-right: math.div($gutter, 2);
		}
	}

	.double-input-col-2 {
		@extend .col-12;
		@extend .col-md-6;

		padding-top: 0;
		padding-bottom: 0;
	}

	.divider {
		width: 100%;
		border-bottom: 1px solid #d1d1d1;
		margin: 24px 0 20px;
	}
}
