.behavior-wrapper {
	width: 100%;
	margin-top: 40px;

	.selects-wrapper {
		width: 100%;
		display: grid;
		grid-gap: 20px;
		grid-template-areas: 'A B B';

		.item-a {
			grid-area: A;
		}
		.item-b {
			grid-area: B;
		}
	}

	.behavior-section-title {
		display: inline-block;
		margin-bottom: 16px;
		color: #5d615d;
		font-size: 16px;
	}

	.loading-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.subirPlanilhaCsv {
	padding: 15px 40px 15px 40px;
	font-size: 0.6875rem;
	font-weight: bold;
	color: #3d3d3d;
	background-color: #fff;
	text-transform: uppercase;
	cursor: pointer;
}

.filterHierarchyContainer {
	margin-top: 48px;
}
