@use 'sass:math';

// VAR DO GRID
$cols: 12;
$gutter: 32px;
$xlg-device: 1536px;
$lg-device: 1200px;
$md-device: 992px;
$sm-device: 768px;
$xs-device: 576px;

%flex {
	display: flex;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

// BOX SIZING
*,
*::after,
*::before {
	box-sizing: border-box;
}

// MEDIA QUERIES
@mixin d($size) {
	@if $size == lg {
		@media screen and (max-width: #{$lg-device}) {
			@content;
		}
	} @else if $size == md {
		@media screen and (max-width: #{$md-device}) {
			@content;
		}
	} @else if $size == sm {
		@media screen and (max-width: #{$sm-device}) {
			@content;
		}
	} @else if $size == xs {
		@media screen and (max-width: #{$xs-device}) {
			@content;
		}
	}
}

// Condicional to IE 10+
@mixin IE() {
	@media all and (-ms-high-contrast: none),
		(-ms-high-contrast: active),
		(-ms-accelerator: true) {
		@content;
	}
}

// FUNÇÃO GRID

@function grid($col, $offset: false) {
	$width: (math.div($col, $cols) * 100) * 1%;
	$result: calc(#{$width});

	@if $offset == offset {
		$gutter: math.div($gutter, 2);
		$result: $width;
	}

	@return $result;
}

// GRID
@mixin structure {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding: math.div($gutter, 2);
	// padding: 16px 24px;

	@include d(sm) {
		max-width: 100%;
	}

	@include d(xs) {
		max-width: 100%;
	}
}

// CLEARFIX

@mixin clearfix {
	&::after,
	&::before {
		content: '';
		display: table;
		clear: both;
	}
}

// CONTAINER

.custom-container {
	max-width: $xlg-device;
	margin: 0 auto;
	padding: calc($gutter * 2) calc($gutter / 2) $gutter;
	display: flex;
	flex-direction: column;
	gap: $gutter;
}

@mixin container {
	max-width: $lg-device;
	margin: 0 auto;
	padding-right: math.div($gutter, 2);
	padding-left: math.div($gutter, 2);
	justify-content: center;
	height: 100%;
	width: 100%;

	@include d(lg) {
		max-width: $md-device;
	}

	@include d(md) {
		max-width: $sm-device;
	}

	@include d(sm) {
		max-width: $xs-device;
	}

	@include d(xs) {
		max-width: $xs-device;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
	}
}

@mixin container-fluid {
	margin: 0 auto;
	padding-right: math.div($gutter, 2);
	padding-left: math.div($gutter, 2);
	// justify-content: center;
	// height: 100%;
	// width: 100%;

	@include d(xs) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.container {
	@include container;
}

.container-fluid {
	@include container-fluid;
}

// ROW

@mixin row {
	margin-right: math.div(-$gutter, 2);
	margin-left: math.div(-$gutter, 2);

	@extend %flex;

	@include d(sm) {
		flex-direction: column;
		justify-content: flex-start;
	}

	@include d(xs) {
		// margin-left: 0px;
		// margin-right: 0px;
		flex-direction: column;
		justify-content: flex-start;
	}

	// @include clearfix;
}

.row {
	@include row;
}

// CLASSES DE EXCEÇÃO

.grow {
	flex-grow: 1;
}

.shrink {
	flex-shrink: 1;
}

.flex {
	@extend %flex;
}

// ******************************************************************************

%grid-column {
	@include structure;
}

$grid-breakpoints: (
	xs: 0,
	sm: $xs-device,
	md: $sm-device,
	lg: $md-device,
	xl: $lg-device
) !default;

@function breakpoint-next(
	$name,
	$breakpoints: $grid-breakpoints,
	$breakpoint-names: map-keys($breakpoints)
) {
	$n: index($breakpoint-names, $name);

	@return if(
		$n < length($breakpoint-names),
		nth($breakpoint-names, $n + 1),
		null
	);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);

	@return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);

	@return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max {
		@media screen and (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

	@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
		.col#{$infix} {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}

		.col#{$infix}-auto {
			flex: 0 0 auto;
			width: auto;
			max-width: none;
		}

		@for $i from 1 through $cols {
			.col#{$infix}-#{$i} {
				max-width: grid($i);
			}
		}

		.order#{$infix}-first {
			order: -1;
		}

		.order#{$infix}-last {
			order: $cols + 1;
		}

		@for $i from 1 through $cols {
			.order#{$infix}-#{$i} {
				order: $i;
			}
		}

		@for $i from 0 through $cols - 1 {
			@if not($infix == '' and $i == 0) {
				.offset#{$infix}-#{$i} {
					margin-left: grid($i, offset);
				}
			}
		}
	}

	@for $i from 1 through $cols {
		.col#{$infix}-#{$i} {
			@extend %grid-column;
		}
	}

	.col#{$infix},
	.col#{$infix}-auto {
		@extend %grid-column;
	}
}

// CONTROL OF THE DISPLAY
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.d#{$infix}-none {
			display: none !important;
		}

		.d#{$infix}-inline {
			display: inline !important;
		}

		.d#{$infix}-inline-block {
			display: inline-block !important;
		}

		.d#{$infix}-block {
			display: block !important;
		}

		.d#{$infix}-table {
			display: table !important;
		}

		.d#{$infix}-table-row {
			display: table-row !important;
		}

		.d#{$infix}-table-cell {
			display: table-cell !important;
		}

		.d#{$infix}-flex {
			display: flex !important;
		}

		.d#{$infix}-inline-flex {
			display: inline-flex !important;
		}
	}
}

// PRINT
@media print {
	.d-print-none {
		display: none !important;
	}

	.d-print-inline {
		display: inline !important;
	}

	.d-print-inline-block {
		display: inline-block !important;
	}

	.d-print-block {
		display: block !important;
	}

	.d-print-table {
		display: table !important;
	}

	.d-print-table-row {
		display: table-row !important;
	}

	.d-print-table-cell {
		display: table-cell !important;
	}

	.d-print-flex {
		display: flex !important;
	}

	.d-print-inline-flex {
		display: inline-flex !important;
	}
}

.col-inside-col {
	padding-bottom: 0;
	padding-top: 0;

	@include d(sm) {
		padding-bottom: math.div($gutter, 2);
		padding-top: math.div($gutter, 2);
	}
}
