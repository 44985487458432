@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
	width: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
	font-size: 16px;
	font-family: 'Inter', sans-serif;
	@include font-type(1);

	font-size: $baseFontSize;

	@include d(xs) {
		font-size: 14px;
	}
}

* {
	outline: 0;
}

input[type='file'] {
	display: none;
}

canvas {
	display: block;
}

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
	object-fit: cover;
	object-position: center;
}

a {
	text-decoration: none;
}

ul,
li {
	list-style: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgb(238, 238, 238);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: lighten(#c1c1c1, 8);
}

/* SITE SELECTION */

// ::selection {
// }

.default-shadow {
	-webkit-box-shadow: 0px 2px 4px #0000003d;
	-moz-box-shadow: 0px 2px 4px #0000003d;
	box-shadow: 0px 2px 4px #0000003d;
}

.separator {
	height: 1px;
	width: 100%;
	background-color: #e6e6e6;
}

.input-primary {
	position: relative;
	width: 100%;

	label {
		position: absolute;
		top: 21px;
		left: 16px;
		color: #ffffffb8;
		font-size: 0.75em;
		letter-spacing: 0.3px;
		text-transform: uppercase;

		cursor: text;
		@include font-type(2);
		@include transition;
	}

	input {
		padding: 26px 16px 10px 16px;
		border: solid 1px #f9f9f929;
		border-radius: 4px;
		color: #eeeeee;
		background: #f9f9f929;
		width: 100%;
		font-size: inherit;
		@include transition;
		@extend %ellipsis;

		&:focus,
		&:valid {
			border: solid 1px white;

			+ label {
				top: 12px;
				left: 16px;
				font-size: 0.625rem;
				color: #ffffffb8;
			}
		}

		&[disabled] {
			border: solid 1px white;
			color: white;
			cursor: not-allowed;

			+ label {
				left: 16px;
				top: 12px;
			}
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px #b34444 inset !important;
			-webkit-text-fill-color: #eeeeee !important;
			box-shadow: 0 0 0 30px #b34444 inset !important;
		}
	}

	.error-msg {
		display: none;
	}

	.info-msg {
		display: block;
		color: white;
		margin-top: 8px;
		text-transform: uppercase;
		font-size: 0.625rem;
		@include font-type(2);
		letter-spacing: 0.5px;
	}

	&.error {
		input {
			border: solid 1px #ff6767;

			+ label {
				color: #ff6767;
			}
		}

		.error-msg {
			display: block;
			color: #ff6767;
			margin-top: 8px;
			text-transform: uppercase;
			font-size: 0.625rem;
			@include font-type(2);
			letter-spacing: 0.5px;
		}
	}
}

.input-secondary {
	@extend .input-primary;

	input {
		border: solid 1px #d1d1d1;
		color: #d1d1d1;

		+ label {
			color: $grey;
			text-transform: uppercase;
		}

		&[disabled] {
			border: solid 1px #d1d1d1;
			color: #d1d1d1;

			+ label {
				color: $grey;
				text-transform: uppercase;
			}
		}

		&:focus,
		&:valid {
			border: solid 1px $grey;
			color: $grey;

			+ label {
				top: 12px;
				left: 16px;
				font-size: 0.625rem;
				color: $grey;
			}
		}

		&.error {
			input {
				border: solid 1px #ff6767;

				+ label {
					color: #ff6767;
				}
			}

			.error-msg {
				display: block;
				color: #ff6767;
				margin-top: 8px;
				text-transform: uppercase;
				font-size: 0.625rem;
				@include font-type(2);
				letter-spacing: 0.5px;
			}
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
			box-shadow: 0 0 0 30px white inset !important;
			-webkit-text-fill-color: #979e97 !important;
		}
	}

	.info-msg {
		display: block;
		color: #979e97;
		margin-top: 8px;
		text-transform: uppercase;
		font-size: 0.625rem;
		@include font-type(2);
		letter-spacing: 0.5px;
	}
}

.input-tertiary {
	@extend .input-secondary;
	margin-bottom: 16px;

	.icon {
		position: absolute;
		right: 16px;
		bottom: calc(50% - 10px);
	}

	input {
		&:focus,
		&:valid {
			background: white;

			+ label {
				color: $red;
			}
		}
	}
	&.error {
		input {
			border: solid 1px #ffa5a5;
		}

		.icon {
			bottom: calc(50%);
		}
	}
}

.input-search {
	@extend .input-primary;

	label {
		color: #5d615d80;
		text-transform: none;
		font-size: 0.875em;
		@include font-type(1);
	}

	input {
		padding: 26px 50px 10px 16px;
		border: solid 1px #d1d1d1;
		color: #5d615d;
		background: transparent;

		&:focus,
		&:valid {
			border: solid 1px #d1d1d1;

			+ label {
				color: #5d615d;
			}
		}

		&[disabled] {
			color: white;

			border: solid 1px white;

			+ label {
				color: white;
			}
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
			box-shadow: 0 0 0 30px white inset !important;

			-webkit-text-fill-color: #5d615d !important;
			box-shadow: 0 0 0 30px white inset !important;
		}
	}

	&.error {
		input {
			border: solid 1px white;

			+ label {
				color: white;
			}
		}
	}

	.magnifier-icon {
		position: absolute;
		top: 18px;
		right: 16px;
	}
}

.button-primary {
	background: #ffffff;
	color: $red;
	padding: 14px 40px;
	border-radius: 4px;
	border: none;
	font-size: 0.625rem;
	text-transform: uppercase;
	letter-spacing: 0.64px;
	@include font-type(2);
	cursor: pointer;
	@include transition;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		background: #d1d1d1;
		color: #5d615d;
		opacity: 1;
		cursor: not-allowed;
	}
}

.green-button {
	@extend .button-primary;

	background: var(--gp-palette-primary-main);
	color: white;
}

.light-green-button {
	@extend .button-primary;

	background: #c9e8aa;
	color: $success;
}

.green-transparent-button {
	@extend .button-primary;

	background: transparent;
	border: 1px solid $success;
	color: $success;
}

.red-button {
	@extend .button-primary;

	background: $red;
	color: white;

	&.csv-link {
		padding-top: 18px;
	}
}

.dark-button {
	@extend .button-primary;

	background-color: $darkGrey;
	color: white;
}

.white-button {
	@extend .button-primary;
	.gp-button {
		&.primary {
		}
		&.secondary {
		}
	}

	background: white;
	color: #5d615d;
	box-shadow: 0px 2px 4px #00000029;

	&.upload-button {
		padding-top: 18px;
	}
}

.transparent-button {
	@extend .button-primary;

	background-color: transparent;
	border: 1px solid $darkGrey;
	color: $darkGrey;
}

.close-button {
	@extend %link-button;
	@include transition;

	&:hover {
		opacity: 0.7;
	}
}

.link-button-green {
	@extend %link-button;
	@include transition;
	color: var(--gp-palette-primary-main);
	font-size: 10px;

	&:hover {
		opacity: 0.7;
	}
}

.select-primary {
	display: inline;
}

.camera-wrapper {
	overflow: auto;
	background: #fafafa;
	position: fixed;
	top: 0;
	bottom: 0;
	.gp-button {
		&.primary {
		}
		&.secondary {
		}
	}
	left: 0;
	right: 0;
	opacity: 1;
	z-index: 9999;
	// pointer-events: none;
}

.modal-primary {
	overflow: auto;
	background: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: -200px;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	z-index: -2;
	pointer-events: none;

	@include transition;

	.modal-bg {
		background: #f9f9f9;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		left: 0;
		margin: 15vh auto 5vh auto;
		max-width: 780px;
		position: absolute;
		right: 0;
		width: fit-content;

		.modal-container {
			padding: 40px 32px;
			width: 100%;

			@include d(xs) {
				padding: 16px;
			}

			// > * {
			// 	border-radius: 4px;
			// 	background: #ffffff;
			// 	padding: 32px;
			// 	max-width: 780px;
			// }
		}

		.full {
			padding: 0;
		}
	}

	&.active {
		opacity: 1;
		top: 0;
		z-index: 999;

		// IE
		pointer-events: all;
	}
}

.quantity-input {
	display: flex;
	justify-content: center;
	align-items: center;

	.increment {
		@extend %link-button;
		margin-left: 16px;
	}

	.decrement {
		@extend %link-button;
		margin-right: 16px;
	}
}

.input-quantity-label {
	.input-value {
		background: #f9f9f900;
		border: 1px solid #d1d1d1;
		border-radius: 4px;
		color: #d1d1d1;
		width: 44px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		@include transition;

		&.active {
			border: 1px solid $success;
			color: #5d615d;
		}

		&:disabled {
			border: none !important;
			color: #5d615d;
			background: #f0f0f0;
		}
	}
}

.checkbox-payment {
	label {
		display: block;
		border: 1px solid $success;
		border-radius: 4px;
		padding: 10px 16px;
		font-size: 0.625rem;
		letter-spacing: 0.64px;
		color: $success;
		text-transform: uppercase;
		@include font-type(1);
		@include transition;
		cursor: pointer;

		&::selection {
			background: transparent;
		}
	}

	input {
		display: none;

		&:checked {
			+ label {
				background: $success;
				border: 1px solid transparent;
				box-shadow: 0px 2px 3px #0000003d;
				color: white;
			}
		}
	}
}

.date-picker-primary {
	position: relative;

	.calendar-icon {
		position: absolute;
		left: 6px;
		top: 6px;
		width: 18px;
		height: 18px;
	}

	.DayPickerInput {
		input {
			border: none;
			background: none;
			border-bottom: 1px solid #979e97;
			padding: 8px 0 8px 34px;
			color: #5d615d;
			@include transition;

			&::placeholder {
				color: #d1d1d1;
			}
		}
	}
}

.DayPicker-Day--today {
	color: $red !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
		.DayPicker-Day--outside
	) {
	background-color: $red !important;
	color: white !important;
}

// ******* ******* ******* ******* ******* ******* *******
// ******* ******* ******* ******* ******* Module Style **
// ******* ******* ******* ******* ******* ******* *******

.col-auth {
	@extend .col-12;
	@extend .col-md-4;
	@extend .offset-md-4;
}

.col-new-account {
	@extend .col-12;
	@extend .col-md-8;
	@extend .offset-md-2;
}

.gradient-container {
	background: linear-gradient(to bottom right, $red, $darkRed);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
}

.page-big-title {
	font-size: 1.125em;
	color: #272927;
	padding-top: 48px;
	padding-bottom: 48px;
	@include font-type(1);
}

.page-title {
	h2 {
		font-size: 0.875em;
		color: #5d615d;
		@include font-type(1);
		padding: 8px 0;
	}

	border-bottom: 1px solid #eeeeee;
}

.no-product-placeholder-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;

	h2 {
		font-size: 0.875em;
		color: #5d615d;
		@include font-type(1);
		padding: 8px 0;
	}

	h4 {
		color: #5d615d80;
		@include font-type(1);
	}
}

%drop-active {
	z-index: 200;
	opacity: 1;
	transform: translateY(0px);
}

.page-header-wrapper {
	background: linear-gradient(to right, $red, $darkRed);

	&.sac {
		background: white;
		box-shadow: 0px 2px 4px #00000029;
	}

	position: fixed;
	width: 100%;
	z-index: 999;

	+ div {
		padding-top: 92px;
	}

	.page-header {
		// @extend .container;
		width: 100%;
		padding: 0 32px;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 80px;
	}

	.menu {
		cursor: pointer;
		min-width: 24px;
	}

	.gp-logo-wrapper {
		margin: 12px;

		.gp-logo {
			width: 124px;
			height: auto;
		}
	}

	.right-corner-wrapper {
		max-height: 100%;
		position: relative;
		z-index: 9999;

		.navbar {
			max-width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			.logout-button-wrapper {
				gap: 8px;
				display: flex;
				align-items: center;

				.logout-button {
					border: none;
					gap: 8px;
					cursor: pointer;
					display: flex;
					align-items: center;
					background-color: transparent;

					span {
						font-size: 12px;
						color: #5d615d;
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		.page-header {
			min-height: 100px;
		}

		.gp-logo {
			height: 40px;
			width: 40px;
		}
	}
}

.cart-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
	border: none;

	&.pointer {
		cursor: pointer;
	}

	.top-row {
		display: flex;
		flex-direction: row;
		height: 25px;

		img {
			height: 100%;
			margin-left: 8px;
		}
	}

	.label {
		border: 1px solid white;
		padding: 4px 10px;
		border-radius: 11px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include transition;

		p {
			color: white;
			font-size: 10px;
			text-transform: uppercase;
			@include font-type(2);
			@include transition;
		}

		&.active {
			// background: $red;

			&.active {
				background: $red;

				p {
					color: white;
				}
			}
		}
	}

	&.sac {
		border: 1px solid $red;

		p {
			color: $red;
			font-size: 10px;
			text-transform: uppercase;
			@include font-type(2);
			@include transition;
		}

		&.active {
			background: $red;

			p {
				color: white;
			}
		}
	}

	&.reverse {
		border: 1px solid $red;

		p {
			color: $red;
			font-size: 10px;
			text-transform: uppercase;
			@include font-type(2);
			@include transition;
		}

		&.active {
			background: $red;

			p {
				color: white;
			}
		}
	}
}

.cart-icon {
	// width: 24px;
	// height: 24px;
	// margin-left: 8px;
}

.total-amount {
	color: #fff;
	font-size: 0.625rem;
	align-self: baseline;
	margin-top: 5px;

	&.reverse {
		color: #5d615d;
		@include font-type(3);
	}
}

.total-points {
	font-size: 0.625rem;
	align-self: baseline;
	margin-top: 4px;
	color: #5ea715;
	@include font-type(3);
}

.dropdown-wrapper {
	.dropdown-menu {
		top: 70px;
		right: 116px;
		display: flex;
		flex-direction: column;
		width: 111px;
		border-radius: 5%;
		background-color: white;
		position: absolute;
		box-shadow: 0px 2px 3px #0000003d;
		z-index: -1;
		opacity: 0;
		transform: translateY(-5px);
		@include transition;

		.dropdown-item {
			color: black;
			padding: 16px 8px;
			border-bottom: 1px solid #eeeeee;
			border-radius: 8%;
			cursor: pointer;
			@include font-type(1);
			@include transition;

			&:last-of-type {
				border: none;
			}

			&:hover {
				opacity: 0.7;
				background-color: #d1d1d1;
			}
		}

		&:hover {
			@extend %drop-active;
		}
	}

	.nav-user {
		position: relative;
		padding: 32px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		cursor: pointer;
		@include font-type(1);
		@include transition;

		&:hover {
			opacity: 0.8;

			+ .dropdown-menu {
				@extend %drop-active;
			}
		}
	}
}

.recover-password-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 5vw;

	header {
		display: flex;
		flex-direction: row;
		margin-bottom: 24px;
		align-items: center;
		justify-content: space-between;

		nav {
			max-width: 32px;

			.back-arrow-wrapper {
				@extend %link-button;
				align-self: center;
				align-items: center;
				float: left;

				.back-arrow {
					width: 32px;
					height: 32px;
				}
			}
		}

		img {
			width: 95px;
			align-self: center;
			justify-self: center;
		}
	}

	.input {
		@extend .input-primary;
		margin-bottom: 40px;
	}

	.button {
		@extend .button-primary;
		align-self: center;
		margin-bottom: 2.5vw;
	}
}

.new-account-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;

	.new-account-wrapper {
		max-width: 800px;
		width: 100%;
		margin-top: 64px;
		padding: 32px;

		.input {
			@extend .input-primary;
			margin-bottom: 20px;
		}

		.header {
			width: 100%;

			.back-arrow-wrapper {
				width: 32px;
				height: 32px;
				@extend %link-button;

				.back-arrow {
					width: 32px;
					height: 32px;
				}
			}

			.header-text {
				font-size: 1.5rem;
				letter-spacing: 0.38px;
				color: #ffffff;
				margin-top: 32px;
				margin-bottom: 48px;
				@include font-type(1);
			}
		}

		.document {
			.input {
				max-width: 360px;
				margin-bottom: 64px;
			}
		}

		.recaptcha-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: 12px;
		}
	}

	.bom-parceiro-logo {
		width: 95px;
		align-self: center;
		justify-self: center;
		margin-bottom: 40px;
	}
}

.form {
	@include transition;

	.form-wrapper {
		.form-title {
			font-size: 1.125rem;
			letter-spacing: 0.018rem;
			color: #ffffff;
			line-height: 1.625rem;
			margin-bottom: 40px;
		}

		.login-redirect {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 32px 0;

			.redirect-text {
				font-size: 0.75rem;
				color: #5d615d;
				letter-spacing: 0.012rem;
				margin: 24px;

				.link {
					margin-left: 0.25rem;
					color: $red;
					text-decoration: underline;
				}
			}

			.button {
				margin-bottom: 24px;
			}
		}

		.button-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.button {
			@extend .button-primary;
		}
	}
}

.confirmation-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 5vw;

	.confirmation-title {
		font-size: 1.5em;
		color: #ffffff;
		text-align: center;
		@include font-type(1);
		margin-bottom: 80px;
	}

	.code-input {
		margin-bottom: 48px;
		display: flex;
		align-self: center;

		&:hover {
			> .code-label {
				border: solid 1px white;
				top: 12px;
				left: 16px;
				font-size: 1.5em;
				color: #ffffffb8;
			}
		}

		.code-label {
			padding: 12px 20px;
			border: solid 1px #f9f9f929;
			border-radius: 4px;
			color: #eeeeee;
			background: #f9f9f929;
			margin-right: 8px;
			height: 55px;
			width: 55px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			@include transition;
			@extend %ellipsis;

			&:last-of-type {
				margin-right: 0;
			}

			&.active {
				border: solid 1px white;
				top: 12px;
				left: 16px;
				font-size: 1.5em;
				color: #ffffffb8;
			}
		}
	}

	.button {
		@extend .button-primary;
		align-self: center;
		margin-bottom: 2.5vw;
	}
}

.change-password-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 5vw;

	header {
		display: flex;
		flex-direction: row;
		margin-bottom: 24px;
		align-items: center;
		justify-content: space-between;

		nav {
			max-width: 32px;

			.back-arrow-wrapper {
				@extend %link-button;
				align-self: center;
				align-items: center;
				float: left;

				.back-arrow {
					width: 32px;
					height: 32px;
				}
			}
		}

		img {
			width: 95px;
			align-self: center;
			justify-self: center;
		}
	}

	.input {
		@extend .input-primary;
		margin-bottom: 8px;
	}

	.button {
		@extend .button-primary;
		align-self: center;
		margin-top: 40px;
		margin-bottom: 2.5vw;
	}
}

.push-notification-modal {
	display: flex;
	flex-direction: column;
	padding: 10px;

	.push-notification-footer-button-wrapper {
		display: flex;
		margin-top: 40px;
		justify-content: flex-end;
	}

	.white-button-background {
		margin-right: 8px;
	}

	.push-notification-modal-impacted_clients-wrapper {
		margin-top: 32px;
	}

	.impacted-clients-counter {
		display: flex;
		align-items: center;

		.impacted-clients-counter-label-inactive {
			font-size: 12px;
			color: #979e97;
			line-height: 1.4;
		}

		.impacted-clients-counter-label-active {
			font-size: 12px;
			color: #ce332e;
			line-height: 1.4;
			font-weight: 500;
		}

		.impacted-clients-counter-value-inactive {
			font-size: 24px;
			margin-left: 24px;
			color: #979e97;
		}

		.impacted-clients-counter-value {
			font-size: 24px;
			margin-left: 24px;
			color: #979e97;
		}
	}

	.push-notification-modal-header {
		display: flex;
		justify-content: space-between;

		.push-notification-modal-title {
			color: #5d615d;
			font-size: 24px;
			font-weight: 'Regular';
		}
	}

	.push-notification-modal-separator-wrapper {
		margin-top: 32px;
	}

	.push-notification-modal-message-title {
		color: #5d615d;
		font-size: 16px;
	}

	.push-notification-modal-message-title-wrapper {
		margin-top: 32px;
		min-width: 600px;
	}

	.push-notification-modal-message-subtitle {
		color: #979e97;
		font-size: 14px;
		margin-top: 20px;
	}

	.push-notification-modal-message-subtitle-wrapper {
		margin-top: 20px;
	}
}

.product-list-page {
	background: #f9f9f9;
	min-height: 100vh;

	.placeholder-wrapper {
		padding: 64px;
		display: flex;
		justify-content: center;
		align-items: center;

		.placeholder-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			h1 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 16px;
				color: #5d615d;
				margin-bottom: 8px;
			}

			h2 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 10px;
				color: #979e97;
				margin-bottom: 32px;
			}

			.gp-logo-wrapper {
				margin: 24px;

				.gp-logo {
					width: 124px;
					height: auto;
				}
			}

			.button {
				@extend .red-button;
			}
		}
	}

	#card-list-combo {
		margin-top: 24px;
	}
}

.push-notification-page-loading {
	flex: 1;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-top: 30px;
}

.push-notification-page {
	background: #fff;
	min-height: 100vh;
	font-weight: 'Regular';
	padding-bottom: 30px;

	.separator-wrapper {
		margin-top: 40px;
	}

	.container-full {
		margin-top: 64px;
		padding: 0 5%;
		width: 100%;

		.customer-table-placeholder {
			width: 100%;
			background-color: #fff;
			height: 165px;
			margin-top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			span {
				text-align: center;
				margin-top: 12px;
				color: #979e97;
				font-size: 14px;
			}
		}

		.notification-data {
			margin-top: 60px;

			#title-input {
				margin-bottom: 20px;
				color: #5d615d;
			}

			.title {
				font-size: 24px;
				color: #5d615d;
				margin-bottom: 20px;
			}

			#message-input {
			}

			.information-footer {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 20px;
			}
		}

		.filters-section {
			margin-top: 40px;
			width: 100%;

			svg {
				color: #ce332e;
			}

			span.css-1v99tuv {
				padding-top: 20px;
			}

			span {
				font-size: 14px;
				font-weight: 'Regular';
			}

			.button-row-wrapper {
				margin-top: 20px;
				display: flex;
				justify-content: flex-end;
				gap: 16px;
			}

			.select-filter-wrapper {
				display: flex;
				gap: 20px;
				width: 100%;
				margin-top: 16px;
				height: 55px;

				.react-select,
				.select-filter {
					width: 100%;
					min-height: 55px;
				}
			}
		}

		.impacted-clients-counter-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.information-footer-impacted-clients-counter {
			margin-right: 72px;
		}

		.impacted-clients-counter {
			display: flex;
			align-items: center;

			.impacted-clients-counter-label-inactive {
				font-size: 12px;
				color: #979e97;
				line-height: 1.4;
			}

			.impacted-clients-counter-label-active {
				font-size: 12px;
				color: #ce332e;
				line-height: 1.4;
				font-weight: 500;
			}

			.impacted-clients-counter-value-inactive {
				font-size: 24px;
				margin-left: 24px;
				color: #979e97;
			}

			.impacted-clients-counter-value {
				font-size: 24px;
				margin-left: 24px;
				color: #979e97;
			}
		}
	}

	.header {
		.title {
			font-size: 24px;
			color: #5d615d;
		}
	}
}

.page {
	background: #f9f9f9;
	display: flex;
	flex-direction: row;
	justify-content: center;
	min-height: 100vh;

	.page-title {
		padding: 8px 0px 16px 0px;
		border: none;
		font-size: 1.125em;
		color: #272927;
	}

	.section-title {
		padding-bottom: 24px;
		color: #5d615d;
		font-size: 0.875em;
	}

	.centralizer {
		@extend .row;
		justify-content: center;
		display: flex;
		flex-direction: row;
	}
}

.my-account-page {
	@extend .page;

	.welcome {
		// padding: 0 86px;
		margin-top: 16px;
		margin-bottom: 32px;
		font-size: toRem(18px);
		color: #5d615d;
		line-height: 24px;
		@include font-type(1);
	}

	.input-wrapper-12 {
		@extend .col-12;
		position: relative;
		padding-top: 0px;
	}

	.input-wrapper-6 {
		@extend .col-6;
		position: relative;
		padding-top: 0px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
		}
	}

	.change-password {
		background-color: transparent;
		border: none;
		color: $red;
		cursor: pointer;
		font-weight: bold;
		position: absolute;
		right: 20px;
		top: 20px;
	}
}

.new-client-page {
	margin: auto;
	width: 50%;

	.flex {
		display: flex;
	}
	.input-wrapper-12 {
		@extend .col-12;
		position: relative;
		padding: 0px;

		&.disabled {
			cursor: not-allowed;
		}
	}

	.input-wrapper-6 {
		@extend .col-6;
		position: relative;
		padding: 0px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
		}

		&.disabled {
			cursor: not-allowed;
		}
	}

	.margin {
		padding-right: 8px;

		@media screen and (max-width: 767px) {
			padding-right: 0;
		}
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
	.border {
		border-bottom: 1px solid #e0e0e0;
		width: 100%;
	}

	.button {
		margin: 32px 0;
	}
	// .description {
	// 	padding-right: 8vw;
	// }

	// .page-title {
	// 	color: #b62a2a;
	// 	font-size: 1.5em;
	// }

	// .section-title {
	// 	color: #ffffff;
	// 	font-size: 0.75em;
	// 	margin-bottom: 8px;
	// }

	// .back-arrow-wrapper {
	// 	@extend %link-button;
	// 	margin-top: 48px;

	// 	margin-bottom: 24px;
	// 	width: 24px;
	// 	height: 24px;

	// 	.back-arrow {
	// 		filter: brightness(5);
	// 		width: 24px;
	// 		height: 24px;
	// 	}
	// }

	// .button {
	// 	@extend .button-primary;
	// 	max-width: 210px;
	// }

	// .change-password {
	// 	background-color: transparent;
	// 	border: none;
	// 	color: $red;
	// 	cursor: pointer;
	// 	font-weight: bold;
	// 	position: absolute;
	// 	right: 20px;
	// 	top: 20px;
	// }
	.react-select__menu {
		z-index: 2;
	}

	.react-select {
		padding-bottom: 16px;
	}
	// .react-select {
	// 	.react-select__control {
	// 		background: #fff;
	// 		padding: 6px;
	// 		border: solid 1px #D1D1D1;
	// 		border-radius: 4px;

	// 		.react-select__indicator {
	// 			color: #fff;
	// 		}

	// 		.react-select__placeholder {
	// 			color: #ffffffb8;
	// 			font-size: 0.75em;
	// 			letter-spacing: 0.3px;
	// 			text-transform: uppercase;

	// 			cursor: text;
	// 			@include font-type(2);
	// 			@include transition;
	// 		}

	// 		.react-select__single-value {
	// 			color: #eeeeee;
	// 		}

	// 		.react-select__input {
	// 			color: #eeeeee;
	// 		}

	// 	}
	// }
}

.checkbox-button {
	background: 0;
	border: 0;
	padding: 16px 0;
	width: 100%;
	cursor: pointer;
	text-align: start;

	span {
		margin-left: 8px;
		font-size: 14px;
		font-weight: normal;
		color: #5d615d;
	}
}

.change-personal-password-page {
	@extend .page;

	.input-secondary {
		padding-bottom: 16px;
	}

	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.back-arrow-wrapper {
		@extend %link-button;
		align-self: center;
		align-items: center;
		margin: 8px 0 24px 0;

		.back-arrow {
			width: 32px;
			height: 32px;
		}
	}
}

.change-personal-email-page {
	@extend .page;

	.input-secondary {
		padding-bottom: 16px;
	}

	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.back-arrow-wrapper {
		@extend %link-button;
		align-self: center;
		align-items: center;
		margin: 8px 0 24px 0;

		.back-arrow {
			width: 32px;
			height: 32px;
		}
	}

	.email-centralizer {
		@extend .row;
		justify-content: center;
		display: flex;
		flex-direction: row;
		text-align: center;

		.email-page-title {
			font-size: 1.125em;
			color: #272927;
			@include font-type(1);
			margin-bottom: 30px;
		}
	}
}

.filters {
	@extend .row;
	display: flex;
	align-items: center;

	padding: 80px 0px 32px 0px;
	position: relative;

	.select-small {
		@extend .col-12;
		@extend .col-md-4;
		@extend .select-primary;
		padding: 4px;

		@include d(sm) {
			padding: 4px 16px;
		}
	}

	.input {
		@extend .input-search;
	}

	.clear-filter-button {
		background: $red;
		padding: 4px 14px;
		border-radius: 12px;
		border: none;
		font-size: 0.625rem;
		letter-spacing: 0.64px;
		color: #ffffff;
		text-transform: uppercase;
		position: absolute;
		right: 16px;
		bottom: 10px;
		cursor: pointer;
		@include transition;

		&:active {
			background: $darkRed;
		}
	}
}

.card-item-wrapper .quantity-badge {
	padding: 6px 9px;
	align-items: center;
	background: $red;
	border-radius: 12px;
	color: #ffffff;
	display: flex;
	font-size: 0.625rem;
	max-height: 25px;
	justify-content: center;
	right: 16px;
	letter-spacing: 0.64px;
	position: absolute;
	text-transform: uppercase;
	top: 16px;

	.cart-icon {
		margin-right: 6px;
		height: auto;
		width: 16px;
	}
}

.card-item {
	background: white;
	border: 1px solid #eeeeee;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 350px;
	// position: relative;

	&.this-product-is-in-cart {
		border: 1px solid var(--gp-palette-primary-main);
	}

	.product-image {
		width: 80px;
		height: 80px;
		margin-bottom: 12px;
	}

	.product-name {
		font-size: 0.875em;
		color: #5d615d;
		text-transform: uppercase;
		@include font-type(1);
		max-width: 100%;

		line-height: 1rem;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		word-break: break-word;
		overflow: hidden;
		height: 3rem;
	}

	.info-price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		//justify-content: end;
		width: 100%;
		border-bottom: 1px solid #eeeeee;
		padding: 10px 0;
		margin-bottom: 5px;

		.price-action {
			display: flex;
			align-items: center;
		}

		div p {
			text-transform: uppercase;
			@include font-type(1);
		}

		.old-price {
			color: #ffa5a5;
			font-size: 0.75rem;
			margin-right: 5px;
			text-decoration: line-through;
		}

		p {
			text-transform: uppercase;
			@include font-type(1);
		}

		.title {
			font-size: 0.75rem;
			color: $grey;
		}

		.price {
			font-size: 0.875rem;
			color: $mediumGrey;
		}

		.new-price {
			font-size: 0.875rem;
			color: #ce332e;
		}
	}

	.card-info {
		// display: flex;
		// align-items: center;
		// justify-content: space-between;
		width: 100%;

		.type {
			padding: 5px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				font-size: 0.625rem;
				color: $grey;
				text-transform: uppercase;
				@include font-type(2);
			}

			.description {
				font-size: 0.625rem;
				color: $mediumGrey;
				text-transform: uppercase;
				@include font-type(2);
			}
		}
	}

	// .quantity-badge {
	// 	padding: 6px 9px;
	// 	align-items: center;
	// 	background: $red;
	// 	border-radius: 12px;
	// 	color: #ffffff;
	// 	display: flex;
	// 	font-size: 0.625rem;
	// 	max-height: 25px;
	// 	justify-content: center;
	// 	left: 220px;
	// 	letter-spacing: 0.64px;
	// 	position: absolute;
	// 	text-transform: uppercase;
	// 	top: 16px;

	// 	.cart-icon {
	// 		margin-right: 6px;
	// 		height: auto;
	// 		width: 16px;
	// 	}
	// }
	.returnable-badge {
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		color: #ffffff;
		display: flex;
		min-height: 24px;
		left: 24px;
		letter-spacing: 0.64px;
		position: absolute;
		top: 24px;
		min-width: 24px;
		z-index: 7;
	}
}

.card-list {
	display: flex;
	flex-wrap: wrap;

	.card-item-wrapper {
		@extend .col-12;

		@extend .col-md-6;
		@extend .col-lg-4;
		@extend .col-xl-3;

		padding: 8px;
		cursor: pointer;
	}

	.combo-section {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
}

.product-info {
	background: white;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 240px;

	.prices {
		display: flex;
		justify-content: space-between;
		align-self: flex-start;
		margin-bottom: 24px;
		flex-wrap: wrap;

		max-width: 100%;

		.price-item {
			padding: 8px;
			min-width: 100px;
			display: flex;
			flex-direction: column;

			.price-title {
				font-size: 0.625rem;
				color: #979e97;
				text-transform: uppercase;
				@include font-type(2);

				margin-bottom: 8px;
			}

			.price-value {
				font-size: 0.875em;
				color: #5d615d;

				@include font-type(1);
			}

			.price-desc {
				font-size: 0.625rem;
				color: #5d615d;
				margin-top: 8px;

				@include font-type(1);
			}

			&.highlight {
				background-color: $success;
				border-radius: 10%;
				@include transition;

				.price-title {
					font-size: 0.625rem;
					color: #fff;
					text-transform: uppercase;
					@include font-type(2);

					margin-bottom: 8px;
				}

				.price-value {
					font-size: 0.875em;
					color: #fff;

					@include font-type(1);
				}

				.price-desc {
					font-size: 0.625rem;
					color: #fff;
					margin-top: 8px;

					@include font-type(1);
				}
			}
		}
	}

	.info-list {
		width: 100%;

		.info-item {
			// margin-right: 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #eeeeee;
			padding: 8px 0;

			&:last-of-type {
				border: none;
			}

			.info-title {
				font-size: 0.625rem;
				color: #979e97;
				text-transform: uppercase;
				@include font-type(2);
			}

			.info-value {
				font-size: 0.625rem;
				text-transform: uppercase;
				color: #5d615d;

				@include font-type(2);
			}
		}
	}
}

.modal-body {
	.card-item {
		display: block;
		width: 332px;
	}

	.collage {
		justify-content: center;
	}

	.product-info {
		padding: 0;
		justify-content: initial;
	}

	.prices {
		justify-content: center;
		gap: 4px;

		.price-item {
			background: #fff;
			border-radius: 4px;
			// cursor: pointer;
		}
	}

	.combo {
		.combo-box {
			border: 1px solid #eeeeee;
			border-radius: 4px;
			background: #fff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			gap: 16px;
			height: 88px;
			padding: 7px;

			figure {
				height: 100%;
				width: 25%;
				text-align: center;

				img {
					height: 100%;
				}
			}

			.combo-info {
				width: 57%;
			}

			.combo-description,
			.price,
			.combo-unit {
				text-transform: uppercase;
				@include font-type(1);
				color: $mediumGrey;
				font-size: 12px;

				strong {
					font-weight: bold;
				}
			}

			.combo-description {
				line-height: 1rem;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: break-word;
				overflow: hidden;
				height: 2rem;
			}

			.price,
			.old-price {
				width: 23%;
			}
		}
	}

	.info-price-action {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 16px;
		background: #fff;
		border-radius: 4px;
		justify-content: space-between;
		box-shadow: 0px 2px 4px #00000014;
	}

	.old-price {
		color: #ffa5a5;
		font-size: 0.75rem;
		margin: 5px 0;
		text-decoration: line-through;
	}

	.unit-type {
		color: #979e97;
		font-size: 0.625rem;
	}

	div p {
		text-transform: uppercase;
		@include font-type(1);
	}

	.price {
		font-size: 1.25rem;
		color: $mediumGrey;
	}

	.button-offer {
		width: 100%;
		background: $red;
		padding: 12px 0;
		border-radius: 4px;
		border: none;
		font-size: 0.813rem;
		color: #ffffff;
		text-transform: uppercase;
		cursor: pointer;
		@include transition;
		margin: 7px 0;

		&:active {
			background: $darkRed;
		}
	}

	.combo-card-container {
		width: 336px;
	}
}

.bonus {
	display: flex;
	padding: 3px 0;

	p {
		color: $red;
		padding: 3px;
		background: #ffd6d6;
		border-radius: 10px;
		font-size: 0.563rem;
		@include font-type(2);
	}
}

.modal-product {
	background-color: inherit;

	* {
		background-color: inherit;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 40px 0;

		.modal-title {
			font-size: 1.125em;
			color: #5d615d;
			@include font-type(1);
		}

		.close-button {
			@extend %link-button;
			@include transition;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	.input-increment-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 16px;
		margin-top: 40px;
	}

	.increment-product {
		display: block;

		@include d(sm) {
			display: none;
		}
	}

	.increment-product-sm {
		display: none;

		@include d(sm) {
			display: block;
		}
	}

	.button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.button {
			@extend .green-button;
		}
	}
}

.cart-page {
	.back-arrow-wrapper {
		@extend %link-button;
		margin-top: 48px;

		margin-bottom: 24px;
		margin-left: 16px;
		width: 24px;
		height: 24px;

		.back-arrow {
			width: 24px;
			height: 24px;
		}
	}

	.button-wrapper {
		text-align: center;
	}
}

.cart-table {
	width: 99.9%;

	thead {
		@include d(sm) {
			display: none;
		}

		tr {
			th {
				padding: 40px 16px 8px 10px;
				border-bottom: 1px solid #eeeeee;
				text-align: left;
				font-size: 0.875em;
				color: #272927;
				@include font-type(2);
			}
		}
	}

	tbody {
		border: 1px solid #eeeeee;

		tr {
			background: #f9f9f9;

			&:nth-child(even) {
				background: white;
			}

			td {
				padding: 16px 10px;
				font-size: 0.875em;
				color: #5d615d;
				vertical-align: middle;
				line-height: toRem(16px);
				@include font-type(1);

				@include d(sm) {
					padding: 8px;
				}
			}

			.old-price {
				color: #ffa5a5;
				font-size: 0.625rem;
				text-decoration: line-through;
			}

			.price {
				font-size: 0.75rem;
			}

			.new-price {
				font-size: 0.75rem;
				color: #ce332e;
			}
		}
	}

	.returnable-icon {
		height: auto;
		width: 27px;
	}

	.table-photo-wrapper {
		width: 55px;
		height: 55px;
		text-align: center;
		background: white;

		img {
			height: 100%;
		}

		@include d(sm) {
			width: 45px;
			height: 45px;
		}

		.returnable-icon {
			width: 23px;
			position: relative;
			top: -34px;

			@include d(sm) {
				width: 18px;
				position: relative;
				top: -29px;
			}
		}
	}

	.trash-wrapper {
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;

		.trash-icon {
			width: 20px;
			height: 20px;
		}
	}

	@include d(sm) {
		.mobile-hidden {
			display: none !important;
		}
	}
}

.discount {
	background: #ffd6d6;
	border-radius: 30px;
	padding: 5px;

	.discount-text {
		text-align: center;
		color: $red;
		font-size: 0.625rem;
		@include font-type(2);
	}
}

.order-table {
	width: 100%;
	margin-bottom: 24px;

	thead {
		@include d(sm) {
			display: none;
		}

		tr {
			th {
				padding: 40px 48px 12px 48px;
				text-align: left;
				font-size: 0.875em;
				color: #272927;
				@include font-type(2);
			}
		}
	}

	tbody {
		tr {
			background: #f9f9f9;
			cursor: pointer;

			&:nth-child(odd) {
				background: white;
			}

			td {
				padding: 26px 48px;
				font-size: 0.875em;
				color: #5d615d;
				vertical-align: middle;
				@include font-type(1);

				@include d(sm) {
					padding: 8px;
				}

				&.normal {
					color: var(--gp-palette-black);
				}

				&.success {
					color: $success;
				}

				&.error {
					color: $red;
				}
			}
		}
	}

	.table-photo-wrapper {
		width: 55px;
		height: 55px;
		text-align: center;
		background: white;

		img {
			height: 100%;
		}

		@include d(sm) {
			width: 45px;
			height: 45px;
		}
	}

	.trash-wrapper {
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;

		.trash-icon {
			width: 20px;
			height: 20px;
		}
	}

	@include d(sm) {
		.mobile-hidden {
			display: none !important;
		}
	}

	.ellipsis {
		@extend %ellipsis;

		@include d(xs) {
			width: 90px;
		}
	}
}

.input-quantity-label {
	.input-value {
		@include d(sm) {
			width: 36px;
			height: 28px;
		}
	}
}

.totals {
	padding: 32px 24px;
	background: #f1f1f1;
	border: 1px solid #d1d1d1;
	border-radius: 4px;
	display: flex;
	flex-direction: column;

	.totals-title {
		font-size: 0.875em;
		letter-spacing: 0.22px;
		color: #979e97;
		margin-bottom: 24px;
		@include font-type(1);
	}

	.totals-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;

		&:last-of-type {
			margin-bottom: 0;
		}

		.totals-description {
			font-size: 14px;
			color: #5d615d;
			@include font-type(1);
		}

		.total-without-discount {
			font-size: 14px;
			color: #6f6f6f;
			@include font-type(1);
		}

		.totals-discount {
			font-size: 14px;
			color: $success;
			@include font-type(1);
		}

		.total-emphazis {
			font-size: 14px;
			color: $darkGrey;
			@include font-type(1);
			font-weight: bold;
		}

		.totals-value {
			font-size: 14px;
			width: 30%;
			text-align: end;
			color: $mediumGrey;
			@include font-type(1);
		}
	}

	.button {
		@extend .green-button;
		align-self: center;
		margin-top: 40px;
		background-color: #ce332e;
	}

	.error-message {
		margin-top: 1rem;
		color: var(--gp-palette-error);
		font-weight: 500;
		font-size: 0.75rem;
		text-align: center;
		line-height: 1.25rem;
	}
}

.min-order {
	align-items: center;
	background: #ead6373d;
	border-radius: 4px;
	border: 1px solid $warningYellow;
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	padding: 32px 16px;
	text-align: center;

	.min-order-subtitle {
		@include font-type(1);
		color: $darkYellow;
		font-size: toRem(14px);
		letter-spacing: 0.2px;
		padding-bottom: toRem(8px);
	}

	.min-order-text {
		@include font-type(1);
		color: $darkYellow;
		font-size: toRem(12px);
		letter-spacing: 0.2px;
		padding-bottom: toRem(8px);
	}
}

.order-summary-page {
	background: #f9f9f9;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	> .header {
		padding: 20px 0 20px 0;

		> .row {
			> .finish-order {
				text-align: right;

				> img {
					width: 32px;
					height: 32px;
					margin: -10px 16px 0 0;
				}

				> h2 {
					font-size: 1.5em;
					letter-spacing: 0.38px;
					color: #272927;
					font-weight: 200 !important;
					display: inline-block;
					padding: 20px 0 0 0;
					@include font-type(1);
				}
			}
		}
	}

	> .payment-steps {
		> .cart-back {
			padding: 20px 0 0 0;

			> button {
				@extend %link-button;
				margin-top: 8px;
				text-decoration: none;
				margin-bottom: 24px;
				margin-left: 16px;
				width: 182px;
				height: 24px;
				@include font-type(1);

				> img {
					width: 24px;
					height: 24px;
					margin-right: 6px;
				}

				> p {
					padding: 14px 0 0 10px;
					display: contents;
					font-size: 1.1em;
					font-weight: 200;
					color: #5d615d;
					@include font-type(1);
				}
			}
		}
		> .steps {
			// padding: 0 160px;
			align-items: center;
			justify-content: center;

			> div {
				display: flex;
				align-items: center;

				.border {
					width: 100px;
					height: 2px;
					background-color: #d1d1d1;
					margin: 0 8px;
				}

				.border-active {
					background-color: $success;
				}
				> i {
					height: 50px;
					width: 50px;
					border-radius: 50%;
					display: inline-block;
					padding: 18px 0 0 21px;
					background-color: #e6e6e6;
					color: #979e97;
				}

				> span {
					color: #d1d1d1;
					font-size: 1.2em;
					margin: 0 0 0 8px;
				}
			}

			> div.active {
				> i {
					background-color: var(--gp-palette-primary-main);
					color: var(--gp-palette-white);
				}

				> span {
					color: var(--gp-palette-primary-main);
				}
			}
		}
	}

	> .payment-step-1 {
		margin: 20px 0 0 0;

		.pay-option-online {
			color: #5d615d;
			padding: 0 0 0 10px;
			font-size: 0.9em;
			cursor: pointer;
			margin-left: 8px;
		}
		> .row {
			> div {
				> h3 {
					margin: 0 0 25px 0;
					font-size: 0.9em;
					color: #5d615d;
					@include font-type(1);
				}

				> .pay-option {
					align-items: center;
					padding: 20px;
					margin: 10px 0;
					background-color: #fff;
					border: 1px solid #f0f0f0;
					border-radius: 4px;

					> hr.payment-separator {
						margin: 20px 0;
						display: none;
						border-top: 1px solid #e0e0e0;
					}

					.payment-credit-card {
						background-color: #fafafa;
					}

					.gap {
						gap: 16px;
						padding: 16px 0;
					}
					.label {
						color: red !important;
						@include font-type(1);
						font-weight: bold;
					}

					.creditCard {
						position: relative;

						.icon {
							position: absolute;
							top: 18px;
							right: 16px;
						}
					}
					> .payment-credit-card-active,
					> .payment-on-delivery-active {
						cursor: pointer;
						display: flex;
						justify-content: space-between;
						align-items: center;

						// > img {
						// 	float: right;
						// 	cursor: pointer;
						// }
					}

					> .container {
						display: none;
						margin-top: 2px;

						> .header-payment-option {
							cursor: pointer;
							background-color: #fafafa;

							> div {
								> h4 {
									color: #5d615d;
									display: inline-block;
									font-size: 0.9em;
									cursor: pointer;
									text-transform: capitalize;
									@include font-type(1);
								}

								> img {
									float: right;
									cursor: pointer;
								}
							}
						}

						> .content-payment-option {
							background-color: #fafafa;
							margin-top: 2px;

							> div {
								> ul {
									> li {
										display: flex;
										margin: 0 0 20px 0;
										align-items: center;

										> i {
											background-color: #fafafa;
											color: #32bcad;
											width: 39px;
											height: 39px;
											border-radius: 50%;
											border: 1px solid #32bcad;
											padding: 12px 15px;
											margin-right: 6px;
											// float: left;
										}
										> p {
											// float: right;
											// position: absolute;
											// margin: 7px 0 0 60px;
											font-size: 0.9em;
											color: #5d615d;
											@include font-type(1);
										}
									}
								}
							}
						}

						.react-select__value-container {
							margin-top: 0px;
						}

						.react-select__placeholder {
							color: #979e97;
							text-transform: uppercase;
							font-size: 12px;
							font-weight: 500;
						}
					}

					.payment-pix {
						> .content-payment-option {
							> div {
								> img {
									margin: 53px 0 0 50px;
								}
							}
						}
					}
				}

				> .pay-option.active {
					border-color: var(--gp-palette-primary-main);

					> .payment-credit-card-active,
					> .payment-on-delivery-active {
						> img {
							// display: none;
						}
					}

					> .container,
					> hr.payment-separator {
						display: block;
					}
				}

				> .order-summary {
					padding: 24px;
					background-color: #f1f1f1;
					border: 1px solid #d1d1d1;

					> ul {
						> li {
							margin: 8px 0 12px 0;

							> label {
								color: #5d615d;
								font-size: 0.9em;
								@include font-type(1);
							}

							> span {
								color: #979e97;
								float: right;
								@include font-type(1);
							}

							> span.green {
								color: #5ea715;
							}

							> span.red {
								color: #ffa5a5;
							}
						}
					}

					> hr {
						margin: 30px 0;
					}

					> .next-step {
						text-align: center;

						> button {
							padding: 12px 32px;
							background: var(--unnamed-color-5ea715) 0% 0%
								no-repeat padding-box;
							background: #5ea715 0% 0% no-repeat padding-box;
							box-shadow: 0px 2px 3px #0000003d;
							border-radius: 4px;
							border: 0px;

							> p {
								color: #fff;
								text-transform: uppercase;
								font-size: 0.8em;
								font-weight: 700;
								@include font-type(1);
							}
						}
					}
				}
			}
		}
	}

	div.row.info {
		margin: 30px 0 0 0;
	}

	.info-text {
		> p {
			line-height: 22px;
		}
	}

	.buttons {
		.white-button {
			background-color: #f0f0f000;
			border: none;
			box-shadow: none;
		}
	}

	@media screen and (max-width: 767px) {
		.payment-credit-card .flex {
			flex-direction: column;
			> div {
				width: 100% !important;
			}
		}

		.gp-logo {
			height: 40px;
			width: 40px;
		}

		.header .row {
			flex-direction: row;
			margin: 0 16px;
		}
	}
}

.payment {
	background: #f1f1f1;
	border-radius: 4px;
	padding: 24px 16px;
	margin-top: 24px;

	.payment-title {
		font-size: toRem(14px);
		color: #5d615d;
		margin-bottom: 16px;
		// text-align: center;
		@include font-type(1);
	}

	.select-date-alert {
		font-size: toRem(14px);
		color: #ce332e;
		margin: 16px 0 16px;
		// text-align: center;
		@include font-type(1);
	}

	.order-date {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;

		.calendar-icon {
			margin-right: 8px;
		}

		.payment-date {
			font-size: toRem(16px);
			color: #5d615d;

			@include font-type(1);
		}
	}

	.date-to-delivery {
		margin-bottom: 32px;

		.date-to-delivery-select {
			width: 349px;
			height: 55px;
		}
	}

	.payment-subtitle {
		font-size: toRem(10px);
		letter-spacing: 0.64px;
		color: #979e97;
		text-transform: uppercase;
		margin-top: 4px;
		margin-bottom: 24px;
		display: block;
		line-height: toRem(14px);
		text-align: justify;
		@include font-type(1);
	}

	.strong {
		@include font-type(3);

		color: #7c7c7c;
	}

	.payment-methods {
		margin-top: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 32px;

		.checkbox-payment {
			margin-right: 6px;
			margin-bottom: 6px;
		}
	}

	.date-picker-wrapper {
		margin-bottom: 40px;
	}
}

.success-page {
	display: flex;
	flex-direction: column;
	margin-top: 5vw;

	.check-icon {
		width: 95px;
		height: 95px;
		align-self: center;
		margin-bottom: 16px;
	}

	.title {
		text-align: center;
		font-size: 2em;
		color: #ffffff;
		@include font-type(1);
		margin-bottom: 24px;

		@include d(sm) {
			font-size: 1.5em;
		}
	}

	.description {
		text-align: center;
		font-size: 1.125em;
		color: #ffffff;
		@include font-type(1);
		margin-bottom: 30px;

		@include d(sm) {
			font-size: 0.875em;
		}
	}

	.button {
		@extend .button-primary;
		align-self: center;
		margin-bottom: 50px;
	}

	.gp-logo {
		align-self: center;
		width: 130px;
		margin-bottom: 16px;
	}
}

.awaiting-approval-sac {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: calc(100vh - (105px + 144px));

	.page-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		max-width: 550px;
		padding: 32px;

		.check-icon {
			width: 95px;
			height: 95px;
			align-self: center;
			margin-bottom: 20px;
		}

		.title {
			text-align: center;
			font-size: toRem(24px);
			font-weight: 300;
			color: $red;
			margin-bottom: 24px;

			@include d(sm) {
				font-size: 1.5em;
			}
		}

		.description {
			text-align: center;
			font-size: toRem(14px);
			color: #5d615d;
			margin-bottom: 40px;
			line-height: toRem(24px);

			@include d(sm) {
				font-size: 0.875em;
			}
		}
	}
}

.awaiting-approval-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;

	.page-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		max-width: 500px;
		padding: 32px;

		.check-icon {
			width: 95px;
			height: 95px;
			align-self: center;
			margin-bottom: 16px;
		}

		.title {
			text-align: center;
			font-size: 2em;
			color: #ffffff;
			@include font-type(1);
			margin-bottom: 24px;

			@include d(sm) {
				font-size: 1.5em;
			}
		}

		.description {
			text-align: center;
			font-size: 1.125em;
			color: #ffffff;
			margin-bottom: 0.5rem;
			line-height: toRem(24px);

			@include font-type(3);

			@include d(sm) {
				font-size: 0.875em;
			}
		}

		.sub {
			color: #ffffff;
			font-size: 0.9rem;
			margin-bottom: 3rem;
		}

		.button {
			@extend .button-primary;
			align-self: center;
			margin-bottom: 50px;
		}

		.help-sac {
			font-size: toRem(14px);
			color: white;
			text-align: center;
			margin-bottom: 32px;
			@include font-type(1);

			.telephone-number {
				display: block;
				font-size: toRem(24px);
				margin-top: 4px;
				@include font-type(3);
			}
		}
	}

	.gp-logo {
		width: 130px;
		margin-bottom: 40px;
	}
}

.bottom-bar-toaster-wrapper {
	@include transition;
	bottom: -150px;
	display: flex;
	justify-content: center;
	padding: 0px 165px;
	position: fixed;
	visibility: hidden;
	width: 100%;
	z-index: -1;

	@include d(sm) {
		padding: 0 16px;
	}

	&.active {
		bottom: 20px;
		visibility: visible;
		z-index: 9;

		&.below-value {
			.bottom-bar-toaster {
				background: #f6f1cb;
				border: 1px solid $darkYellow;
			}
		}
	}

	.bottom-bar-toaster {
		align-items: center;
		background-color: #eaf1d9;
		border-radius: 4px;
		border: 1px solid #a1c349;
		display: flex;
		justify-content: space-between;
		max-width: 1000px;
		padding: 0px 52px;
		position: relative;
		width: 100%;

		@include d(sm) {
			flex-direction: column;
			height: 180px;
			padding: 16px;
		}

		.left-side {
			align-items: center;
			display: flex;
			flex-direction: row;
			max-width: 25%;
			padding: 16px 0px;
			width: 100%;

			@include d(sm) {
				flex-wrap: wrap;
				justify-content: center;
				flex-direction: column;
				width: 100%;
				max-width: 100%;
				text-align: center;
				padding: 0;
			}

			.item-photo {
				border-radius: 4px;
				height: 48px;
				margin-bottom: 8px;
				position: relative;
				width: 48px;
				background: white;
				text-align: center;
				border-radius: 4px;

				.badge {
					background: $red;
					border-radius: 12px;
					color: white;
					font-size: 0.625rem;
					font-size: toRem(10px);
					left: -15px;
					letter-spacing: 0.64px;
					padding: 4px 14px;
					position: absolute;
					text-transform: uppercase;
					top: 15px;
					z-index: 7;

					@include d(sm) {
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.image {
					height: 100%;
				}
			}

			.bar-info {
				margin-left: 16px;
				margin-right: 16px;

				.message {
					@include font-type(1);
					font-size: 1.125em;
					color: #5ea715;
					font-size: 14px;
					margin-bottom: 8px;
				}

				.item-name {
					font-size: 0.625rem;
					color: #5d615d;
					text-transform: uppercase;
				}
			}
		}

		.toaster-center {
			align-items: center;
			border-left: 1px solid #a1c349;
			border-right: 1px solid #a1c349;
			display: flex;
			height: 100%;
			justify-content: center;
			margin: -24px 0px -24px 0px;
			max-width: 50%;
			width: 100%;

			@include d(sm) {
				border: 0;
			}

			.cart-button {
				@extend .default-shadow;
				@extend .button-primary;
				background: var(--gp-palette-primary-main);
				color: var(--gp-palette-white);
				margin: 0px 80px;
				padding: 10px 32px;
			}

			.min-order-warning {
				align-items: center;
				border-radius: 4px;
				display: flex;
				flex-direction: column;
				margin-top: 8px;
				// padding: 32px 16px;
				text-align: center;

				.min-order-subtitle {
					@include font-type(1);
					color: $darkYellow;
					font-size: toRem(14px);
					letter-spacing: 0.2px;
					padding-bottom: toRem(8px);
				}

				.min-order-text {
					@include font-type(1);
					color: $darkYellow;
					font-size: toRem(12px);
					letter-spacing: 0.2px;
					padding-bottom: toRem(8px);

					.bold {
						@extend .min-order-text;
						@include font-type(3);
					}
				}
			}
		}

		.right-side {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			max-width: 25%;
			width: 100%;

			@include d(sm) {
				display: none;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;
			}

			.close {
				cursor: pointer;
				position: absolute;
				right: 24px;
				top: 28px;

				@include d(sm) {
					right: 8px;
					top: 8px;
				}
			}
		}
	}
}

.inside-panel {
	.layer-active {
		background: rgba(0, 0, 0, 0.56);
		inset: 0px;
		position: fixed;
		z-index: 10;
		transition: all 3s;
		cursor: pointer;
	}

	nav {
		$inside-panel-width: 330px;
		@include boxshadow;
		@include transition;
		align-items: flex-start;
		background: $darkGrey;
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: flex-start;
		left: -$inside-panel-width;
		position: fixed;
		width: $inside-panel-width;
		z-index: 20;

		&.sac {
			background: $mediumGrey;
		}

		ul {
			width: 100%;
		}

		.menu-top {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-top: 80px;

			.user-name {
				padding: 80px 0px 8px 0px;

				span {
					@include font-type(1);
					color: white;
					font-size: 18px;
				}
			}

			.profile-link {
				@include font-type(1);
				color: #d1d1d1;
				cursor: pointer;
				font-size: 12px;
				letter-spacing: 0.3px;
				margin-bottom: 80px;

				&:hover {
					text-decoration: underline;
				}
			}

			.spacer80px {
				margin-bottom: 80px;
			}
		}

		.menu-main {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			flex: 1;
			margin-top: 40px;
		}

		.menu-bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		.back {
			font-size: 1.125em;
			color: white;
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0px;
		}

		.divider {
			height: 1px;
		}

		.user-name {
			padding: 40px;

			span {
				@include font-type(1);
				font-size: 1.5em;
				color: white;
			}
		}

		.nav-links {
			margin-top: 0;

			li {
				.nav-link {
					padding: 22px 40px;
					width: 100%;
					display: flex;
					align-items: center;
					cursor: pointer;
					@include transition;

					.nav-text {
						display: block;
						color: white;
						font-size: 1em;
						@include font-type(1);
					}

					.icon {
						margin-right: 8px;
						color: white;
					}

					&.active {
						background: white;
						border-radius: 2px;
						width: auto;
						padding: 16px 18px;
						margin: 0px 24px;
						box-shadow: 0px 2px 4px #00000029;

						.nav-text {
							color: $red;
							font-size: 14px;
							@include font-type(3);
						}
					}

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}

		.logout {
			display: flex;
			justify-content: flex-end;

			.nav-link {
				@extend %link-button;
				text-decoration: none;
				padding: 22px 40px;
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				cursor: pointer;
				@include transition;

				.nav-text {
					display: block;
					color: white;
					font-size: 1em;
				}

				.icon {
					margin-right: 8px;
					color: white;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}

		main {
			overflow: hidden;
			overflow-y: auto;
			height: 100vh;

			@include transition;
		}

		&.active {
			left: 0;

			+ .nav-background {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: black;
				opacity: 0.5;
				z-index: 10;
			}
		}
	}
}

.order-history {
	background: #f9f9f9;
	min-height: 100vh;

	.order-number-badget {
		background: #e8e8e8;
		height: 51px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 16px;
		border-radius: 4px 4px 0px 0px;

		span {
			color: #5d615d;
			font-size: 14px;
		}
	}

	.box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 0;

		.info-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				height: 100%;

				.price-action {
					color: #ffa5a5;
					font-size: 14px;
				}
			}

			#cep {
				margin-top: 16px;
			}
		}
	}

	.destinary-card-box {
		margin-top: 16px;
	}

	.destinatary-box {
		@extend .box;
	}

	.placeholder-wrapper {
		padding: 64px;
		display: flex;
		justify-content: center;
		align-items: center;

		.placeholder-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			h1 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 16px;
				color: #5d615d;
				margin-bottom: 8px;
			}

			h2 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 10px;
				color: #979e97;
				margin-bottom: 32px;
			}

			.gp-logo-wrapper {
				margin: 24px;

				.gp-logo {
					width: 124px;
					height: auto;
				}
			}

			.button {
				@extend .red-button;
			}
		}
	}

	.header-wrapper {
		display: flex;
		align-items: center;

		@include d(sm) {
			flex-direction: column;
			align-items: flex-start;
		}

		.last-info-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 26px;
			margin-right: 32px;

			@include d(sm) {
				flex-direction: column;
				align-items: flex-start;
			}

			h3 {
				font-size: 1.125em;
				letter-spacing: 0px;
				color: #272927;
				margin-right: 16px;
				@include font-type(1);

				@include d(sm) {
					margin-bottom: 4px;
				}
			}

			span {
				font-size: 0.875em;
				letter-spacing: 0.22px;
				color: #979e97;
				@extend %ellipsis;
				@include font-type(1);
			}
		}
	}

	.no-last-placeholder {
		font-size: 0.875em;

		color: #979e97;
		margin-bottom: 16px;
		@include font-type(1);
	}

	.no-data-placeholder {
		display: flex;
		justify-content: center;
		align-self: center;
		padding: 24px;
		font-size: 1.5em;

		color: #979e97;
		margin-bottom: 16px;
		@include font-type(1);
	}
}

.box {
	background: white;
	border-radius: 4px;
	padding: 32px 24px;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include d(sm) {
		flex-direction: column;
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 4px;

		.param {
			font-size: 0.875em;
			color: #5d615d;
			@include font-type(1);
		}

		.value {
			font-size: 0.875em;
			color: #979e97;
			@include font-type(1);
			margin-left: 8px;
		}

		&.space {
			margin-bottom: 24px;
		}
	}

	.detail-button {
		border: 1px solid $red;
		border-radius: 4px;
		padding: 10px 16px;
		color: $red;
		font-size: 0.625rem;
		text-transform: uppercase;
		letter-spacing: 0.64px;
		cursor: pointer;
		@include font-type(1);
		@include transition;

		@include d(sm) {
			margin-top: 16px;
		}

		&:hover {
			background: $red;
			color: white;
		}
	}
}

.order-detail {
	background: #f9f9f9;
	min-height: 100vh;
	padding-bottom: 120px;

	.order-number-badget {
		background: #e8e8e8;
		height: 51px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 16px;
		border-radius: 4px 4px 0px 0px;

		span {
			color: #5d615d;
			font-size: 14px;
		}
	}

	.box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 0;

		.info-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				height: 100%;

				.price-action {
					color: #ffa5a5;
					font-size: 14px;
				}
			}

			#cep {
				margin-top: 16px;
			}
		}
	}

	.destinary-card-box {
		margin-top: 16px;
	}

	.destinatary-box {
		@extend .box;
	}

	.back-arrow-wrapper {
		@extend %link-button;
		margin-top: 48px;

		margin-bottom: 24px;
		width: 24px;
		height: 24px;

		.back-arrow {
			width: 24px;
			height: 24px;
		}
	}

	.header-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		@include d(sm) {
			flex-direction: column;
			align-items: flex-start;
		}

		.last-info-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			margin-right: 32px;

			@include d(sm) {
				flex-direction: column;
				align-items: flex-start;
			}

			h3 {
				font-size: 1.125em;
				letter-spacing: 0px;
				color: #272927;
				margin-right: 16px;
				@include font-type(1);

				@include d(sm) {
					margin-bottom: 4px;
				}
			}

			span {
				font-size: 0.875em;
				letter-spacing: 0.22px;
				color: #979e97;
				@extend %ellipsis;
				@include font-type(1);
			}
		}
	}

	.no-last-placeholder {
		text-align: center;
		font-size: 0.875em;

		color: #979e97;
		margin-bottom: 16px;
		@include font-type(1);
	}

	.centralized-button {
		text-align: center;
	}
}

.confirmation-modal {
	max-width: 400px;
	color: #5d615d;
	font-size: 0.875rem;
	line-height: 1.25rem;

	.buttons-wrapper {
		text-align: center;
		flex-direction: row;
		display: flex;
		width: 100%;
		place-content: center;
		place-items: center;
		min-height: 75px;
	}

	.transparent-button {
		text-align: center;
	}

	.dark-button {
		text-align: center;
	}

	.close-button {
		position: relative;
		float: right;
		top: -35px;
	}

	h2 {
		font-size: 1.125rem;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 0.875rem;
		margin-bottom: 24px;
	}

	p {
		b {
			font-weight: bold;
		}
	}
}

.m-confirmation-modal {
	width: 427px;
	max-height: 296px;
	color: #5d615d;
	font-size: 1rem;
	line-height: 1.25rem;

	.buttons-wrapper {
		text-align: center;
		flex-direction: row;
		display: flex;
		width: 100%;
		margin: 0 80px;
		justify-content: space-between;
	}

	.transparent-button {
		text-align: center;
	}

	.dark-button {
		text-align: center;
	}

	.close-button {
		position: relative;
		float: right;
		top: -35px;
	}

	h2 {
		font-size: 1.125rem;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 0.875rem;
		margin-bottom: 24px;
	}

	p {
		b {
			font-weight: bold;
		}
	}
}

.timeline-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	.timeline {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;

		@include d(xs) {
			flex-direction: column;
		}

		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 24px;
			position: relative;
			margin-bottom: 16px;
			z-index: 7;
			width: 150px;

			> * {
				z-index: 7;
			}

			@include d(xs) {
				margin-right: 0;

				> * {
					background: #f9f9f9;
					padding: 2px;
				}
			}

			.circle {
				width: 12px;
				height: 12px;
				background: $success;
				border-radius: 6px;
				margin-bottom: 12px;
			}

			.text {
				font-size: 0.625rem;
				letter-spacing: 0.64px;
				color: $success;
				text-transform: uppercase;
				margin-bottom: 4px;
				text-align: center;

				@include font-type(1);
			}

			.date {
				font-size: 0.625rem;
				letter-spacing: 0.64px;
				color: #979e97;
				text-transform: uppercase;
				@include font-type(1);
			}

			&.success {
				.circle {
					background: $success;
				}

				.text {
					color: $success;
				}
			}

			&.neutral {
				.circle {
					background: #979e97;
				}

				.text {
					color: #979e97;
				}
			}

			&.error {
				.circle {
					background: $error;
				}

				.text {
					color: $error;
				}
			}

			&::after {
				position: absolute;
				right: -100px;
				top: 5px;
				content: '';
				display: block;
				width: 120%;
				height: 1px;
				background: #e6e6e6;
				z-index: 2;

				@include d(xs) {
					position: absolute;
					right: unset;
					left: unset;
					top: 12px;

					width: 1px;
					height: 120%;
				}
			}

			&:last-of-type {
				margin-right: 0;

				&::after {
					display: none;
				}
			}
		}
	}
}

.auth-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('../images/login-background.png');

	.auth-wrapper {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex: 1;
		width: 100%;
		padding: 32px;

		@include d(sm) {
			flex-direction: column-reverse;
			padding: 32px 0;
		}

		.message-container {
			padding: 32px;

			h2 {
				font-size: 2.25em;
				line-height: 48px;
				color: #eeeeee;
				max-width: 500px;
				@include font-type(2);

				@include d(sm) {
					font-size: 1.75em;
					line-height: 37px;
				}
			}
		}

		.login-container {
			height: 100%;
			position: relative;
			z-index: 1;
			height: 100%;
			padding: 32px;
			background-color: rgba(234, 0, 0, 0.8);
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			align-items: center;

			@include d(sm) {
				width: 100%;
			}

			.gp-logo {
				height: auto;
				width: 170px;
				align-self: center;
				margin-bottom: 24px;
			}

			.input {
				@extend .input-primary;
				width: 344px;
				margin-bottom: 16px;

				@include d(sm) {
					width: 100%;
				}
			}

			.button {
				@extend .button-primary;
				align-self: center;
				margin-bottom: 24px;
			}

			.recover-password-link {
				@extend %link-button;
				font-size: 0.625rem;
				color: #eeeeee;
				text-transform: uppercase;
				margin-bottom: 24px;
				@include font-type(2);
				@include transition;

				.recover-password-link {
					@extend %link-button;
					@include font-type(2);
					@include transition;
					color: #eeeeee;
					font-size: 10px;
					margin-bottom: 24px;
					text-transform: uppercase;

					&:hover {
						opacity: 0.7;
					}
				}
			}

			.first-access {
				@extend %link-button;
				@include font-type(1);
				@include transition;
				align-self: center;
				color: #ffffff;
				font-size: 22px;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}

.footer {
	height: 17%;
	width: 100%;
	padding: 31px;
	background: linear-gradient(to right, $red, $darkRed);
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;

	@include d(xs) {
		flex-direction: column;
	}

	&__logo {
		height: auto;
		width: 80px;

		@include d(sm) {
			margin-bottom: 1.5rem;
			width: 103px;
		}
	}

	.footer__text {
		h3 {
			font-size: 11px;
			height: 31px;
			width: 272px;
		}

		a {
			border: 1px solid #ffff;
			background: transparent;
			border: 1px solid #ffffff;
			border-radius: 4px;
			cursor: pointer;
			text-decoration: none;
			color: #fff;
			font-size: 10px;
			font-weight: bold;
			padding: 13px;
			width: 183px;
			height: 53px;

			& + a {
				margin-left: 20px;
			}
		}
	}

	&__text {
		@include font-type(1);
		justify-content: space-between;

		text-align: left;
		font-size: toRem(11px);
		letter-spacing: toRem(0.62px);
		line-height: toRem(16px);
		color: #ffffff;

		@include d(xs) {
			margin-bottom: 1.5rem;
			text-align: center;
			font-size: toRem(14px);
		}

		&--big-nums {
			@include font-type(1);
			margin: 12px 0 0 0;
			font-size: toRem(20px);
			line-height: toRem(26px);

			@include d(xs) {
				margin-bottom: 1.5rem;
				text-align: center;
				font-size: toRem(24px);
			}
		}
	}
}

.payment-instructions {
	text-transform: uppercase;
	font-size: toRem(10px);
	color: #979e97;
	letter-spacing: 0.64px;
	display: block;
	margin-top: 4px;
	margin-bottom: 4px;
	line-height: toRem(14px);
	text-align: justify;
	@include font-type(1);
}

.register-resale-page {
	background-color: #f9f9f9;
	min-height: 100vh;

	.search-input {
		margin-top: 32px;
	}
}

.payment-instructions-success {
	@include font-type(1);
	color: #ffffff;
	display: block;
	font-size: 0.625rem;
	letter-spacing: 0.64px;
	margin-bottom: 42px;
	margin-top: 4px;
	text-align: center;
	text-transform: uppercase;
}

.resale-table-wrapper {
	.resales-title {
		@include font-type(2);
		color: #5d615d;
		font-size: toRem(24px);
		margin: 60px 0 0 0;
	}

	.red-button {
		@extend .default-shadow;
	}

	.resale-table-header {
		@include font-type(2);
		color: $red;
		font-size: toRem(12px);
	}

	.resale-table-field {
		padding: 24px 16px;
		// width: 100%;
	}

	.resale-text-field {
		@include font-type(1);
		color: $mediumGrey;
		font-size: toRem(14px);
	}
}

.resale-table {
	@extend .cart-table;

	thead {
		@include d(sm) {
			display: none;
		}

		tr {
			th {
				border-bottom: 0px;
			}
		}
	}

	tbody {
		border: 0px;

		tr {
			&:nth-child(even) {
				background-color: #f9f9f9;
			}

			&:nth-child(odd) {
				background-color: white;
			}
		}
	}
}

.param-table-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.param-title {
		@include font-type(1);
		color: #5d615d;
		font-size: 24px;
	}

	.red-button {
		@extend .default-shadow;
	}

	.param-table-header {
		@include font-type(2);
		color: $red;
		font-size: toRem(12px);
	}

	.red-button-container {
		display: flex;
		justify-content: flex-end;
	}

	.param-text-field {
		@include font-type(1);
		color: $mediumGrey;
		font-size: toRem(14px);
	}
}

.param-table {
	.param-table-field-wrapper {
		min-width: 200px;
	}

	.time-picker {
		width: 100%;
	}

	thead {
		@include d(sm) {
			display: none;
		}

		tr {
			th {
				border-bottom: 0px;
				color: $red;
				white-space: nowrap;
			}
		}
	}

	tbody {
		border: 0px;

		tr {
			&:nth-child(even) {
				background-color: #f9f9f9;
			}

			&:nth-child(odd) {
				background-color: white;
			}

			td {
				// display: flex;
				// flex-direction: column;
				// place-items: center;
				// background: #CE332E;
				vertical-align: middle;
			}
		}
	}

	.time-picker {
		// background-color: #CE332E;
		border-style: solid;
		border-width: 1px;
		border-color: $grey;
		padding: 6px;

		.MuiFormLabel-root {
			@include font-type(3);
			font-size: toRem(13px);
			color: $red;
		}

		.MuiInputLabel-formControl {
			top: 0px;
			left: 12px;

			&.Mui-focused,
			&.MuiFormLabel-filled {
				top: 6px;
				left: 12px;
			}
		}

		.Mui-focused {
			border-bottom: 0px;
		}

		.MuiInputBase-root {
			@include font-type(1);
			font-size: toRem(14px);
			color: #5d615d;

			input {
				padding: 0px 0px 4px 6px;
			}
		}

		.MuiInput-underline:before {
			border-bottom: 0px;
		}

		.MuiInput-underline:not(.Mui-disabled):before {
			border-bottom: 0px;
		}

		.MuiTouchRipple-root {
			margin: 0px 12px 24px 0px;
		}

		.MuiInput-underline:hover {
			border-bottom: 0px;
		}

		.MuiInput-underline:after {
			border-bottom: 0px;
		}

		.MuiIconButton-root {
			&:hover {
				opacity: 0.6;
				background-color: rgba(0, 0, 0, 0);
			}
		}
	}
}

.param-select-wrapper {
	margin-right: -16px;
}

.param-button-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.values-subtitle {
	padding-bottom: 8px;
	border-bottom-style: solid;
	border-bottom-color: #e6e6e6;
	border-bottom-width: 1px;
}

.param-input {
	@extend .input-secondary;

	input {
		border: solid 1px #d1d1d1;
		color: #d1d1d1;

		+ label {
			color: $grey;
			text-transform: uppercase;
		}

		&[disabled] {
			border: solid 1px #d1d1d1;
			color: #d1d1d1;

			+ label {
				color: $grey;
				text-transform: uppercase;
			}
		}

		&:focus,
		&:valid {
			border: solid 1px $grey;
			color: $grey;

			+ label {
				top: 12px;
				left: 16px;
				font-size: toRem(10px);
				color: $red;
				@include font-type(3);
			}
		}
	}
}

.param-modal {
	color: #5d615d;
	font-size: 0.875rem;
	min-width: 420px;
	width: 100%;

	.close-wrapper {
		right: 0%;
	}

	.param-input {
		margin-bottom: 8px;
	}

	.param-modal-title {
		padding: 0px 0px 24px 0px;
		color: #5d615d;
		font-size: toRem(24px);
		@include font-type(2);
	}

	.buttons-wrapper {
		text-align: center;
		flex-direction: row;
		display: flex;
		width: 100%;
	}

	.transparent-button {
		text-align: center;
	}

	.dark-button {
		text-align: center;
	}

	.close-button {
		position: relative;
		float: right;
		top: -35px;
	}

	h2 {
		font-size: 1.125rem;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 0.875rem;
		margin-bottom: 24px;
	}

	p {
		b {
			font-weight: bold;
		}
	}
}

.intern-container {
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 100vh;
	justify-content: flex-end;
	padding: 110px;
	width: 100%;

	@include d(xs) {
		padding: 0;
	}

	.intern-wrapper {
		align-items: center;
		background: white;
		border-radius: 4px;
		display: none;
		flex-direction: column;
		max-width: 400px;
		min-width: 400px;
		padding: 56px 32px;

		@include d(xs) {
			max-width: unset;
			min-width: unset;
			width: 100%;
		}

		&.active {
			display: flex;
		}

		.gp-logo {
			align-self: center;
			height: auto;
			margin-bottom: 24px;
			width: 170px;
		}

		.input {
			@extend .input-secondary;
			margin-bottom: 16px;
			min-width: 344px;

			@include d(xs) {
				min-width: 0px;
			}
		}

		.button {
			@extend .red-button;
			align-self: center;
			margin-top: 16px;
		}

		.recover-password-link {
			@extend %link-button;
			@include font-type(2);
			@include transition;
			color: #5d615d;
			font-size: 10px;
			margin-bottom: 24px;
			text-transform: uppercase;

			&:hover {
				opacity: 0.7;
			}
		}

		.back-button-wrapper {
			width: 100%;
			margin-bottom: 16px;

			.back-arrow-button {
				@extend %link-button;
				align-items: center;
				display: flex;
				text-decoration: none;

				.back-arrow {
					margin-right: 8px;
				}

				.text {
					@include font-type(1);
					color: #ce332e;
					font-size: 0.75rem;
				}
			}
		}

		.message {
			@include font-type(1);
			color: #5d615d;
			font-size: 0.875rem;
			margin-bottom: 40px;
			width: 100%;
		}

		.success-wrapper {
			align-items: center;
			display: flex;
			flex-direction: column;

			.check-circle {
				margin-top: 24px;
				margin-bottom: 16px;
			}

			.success-message {
				@include font-type(1);
				color: #ce332e;
				font-size: 1rem;
				margin-bottom: 24px;
			}
		}
	}
}

.returnable-agreement {
	margin-bottom: toRem(24px);

	.returnable-container {
		background-color: var(--gp-palette-background-default);
		border-radius: 4px;
		color: var(--gp-palette-returnable-main);
		display: flex;
		flex-direction: row;
		margin-bottom: 1rem;
		padding: 16px;

		.icon-wrapper {
			margin-right: 1rem;
			min-width: 27px;
			text-align: center;

			.returnable-icon {
				height: auto;
				width: 27px;
			}
		}

		.text-message {
			margin-top: 0.5rem;

			h3 {
				font-size: 0.875rem;
				margin-bottom: 0.5rem;
			}

			p {
				font-size: 0.75rem;
				line-height: 1.25rem;
			}
		}
	}

	.checkbox {
		color: $darkGrey;
		display: flex;
		flex-direction: column;
		font-size: 0.875rem;

		label {
			color: $darkGrey;
			margin-bottom: 0.5rem;
		}

		.error-message {
			display: flex;
			flex-direction: row;
			padding-left: 2px;

			img {
				height: 16px;
				width: 16px;
				margin-right: 4px;
			}

			p {
				color: var(--gp-palette-error);
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 1.25rem;
			}
		}
	}
}

.checkbox-group {
	margin-bottom: 40px;
}

.checkbox {
	margin-bottom: 16px;

	label {
		cursor: pointer;

		input {
			margin-right: 8px;
		}

		display: flex;
		align-items: center;
		font-size: toRem(14px);
		letter-spacing: 0.22px;
		line-height: toRem(20px);
		color: #ffffff;

		a {
			@include transition;
			color: #ffffff;
			font-size: toRem(14px);
			text-decoration: underline;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

.collage {
	margin-bottom: 16px;
	display: flex;
	align-items: center;

	img {
		margin-right: 8px;
		padding: 8px;
		background-color: #f9f9f9;
		border-radius: 4px;
		width: 95px;
		height: 95px;

		cursor: pointer;

		&:last-of-type {
			margin-right: 0px;
		}
	}
}

// .react-images__footer {
// 	justify-content: center !important;
// 	position: relative !important;
// 	background: none !important;
// 	padding: 32px 20px 20px !important;
// }

.pending-approval {
	background: #f9f9f9;
	min-height: 100vh;

	.title {
		font-size: toRem(24px);
		color: #5d615d;
		margin-top: 64px;
		@include font-type(1);
	}

	.title-user {
		font-size: toRem(24px);
		color: #5d615d;
		@include font-type(1);
	}

	.back-button-wrapper {
		width: 100%;
		margin-top: 32px;
		margin-bottom: 32px;

		.back-arrow-button {
			@extend %link-button;
			text-decoration: none;
			display: flex;
			align-items: center;

			.back-arrow {
				margin-right: 8px;
			}

			.text {
				font-size: 0.75rem;
				color: #ce332e;
				@include font-type(1);
			}
		}
	}

	.pending-user-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 48px;

		.buttons {
			.button-red {
				@extend .red-button;
				align-self: center;
				padding: 14px 40px;
			}

			.button-white {
				@extend .white-button;
				align-self: center;
				padding: 14px 40px;
				margin-right: 8px;
			}
		}
	}

	.form {
		@include d(md) {
			padding: 0;
		}

		.reason {
			border-bottom: 1px solid #d1d1d1;
			color: $red;
			font-size: toRem(12px);
			margin: 0 0 15px 0;

			.sac-status {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;

				padding: 0 0 12px;
			}

			.text {
				color: $darkGrey;
			}
		}

		.form-title {
			font-size: toRem(14px);
			letter-spacing: 0.22px;
			color: #5d615d;
			margin-bottom: 24px;
		}

		.input {
			@extend .input-secondary;
			background: white;
			margin-bottom: 16px;

			input {
				color: #5d615d !important;
			}

			label {
				color: #979e97 !important;
			}
		}

		.double-input-col-1 {
			@extend .col-12;
			@extend .col-md-6;

			padding-top: 0;
			padding-bottom: 0;
			padding-right: 0;

			@include d(md) {
				padding-right: math.div($gutter, 2);
			}
		}

		.double-input-col-2 {
			@extend .col-12;
			@extend .col-md-6;

			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
.custom-table-container {
	width: 100%;
	overflow-x: auto;

	table {
		width: 100%;
	}
}

.param-table th,
.param-table td {
	padding: 8px;
	text-align: left;
}

.detail-resource__header {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
}

.table-wrapper {
	overflow-x: auto;
	padding-bottom: 48px;

	.table {
		width: 99.9%;
		min-width: $md-device;

		&__button {
			background: none;
			border: none;
			cursor: pointer;
			align-self: center;
		}

		thead {
			tr {
				th {
					padding: 40px 16px 8px 16px;
					text-align: left;
					font-size: toRem(12px);
					color: #ce332e;
					@include font-type(1);
				}
			}
		}

		tbody {
			tr {
				background: #f9f9f9;

				&:nth-child(even) {
					background: white;
				}

				td {
					padding: 20px 16px;
					font-size: 14px;
					vertical-align: middle;
					color: #5d615d;
					@include font-type(1);
				}
			}
		}

		@include d(sm) {
			.mobile-hidden {
				display: none !important;
			}
		}

		.ellipsis {
			@extend %ellipsis;
			max-width: 150px;

			@include d(sm) {
				width: 200px;
			}

			@include d(xs) {
				width: 50px;
			}
		}
	}

	.table-footer {
		margin-top: 24px;
		border-top: 1px solid #d1d1d1;

		.result-span {
			display: inline-block;
			margin-top: 12px;
			margin-bottom: 18px;
			font-size: toRem(14px);
			color: #5d615d;
			@include font-type(1);
		}

		.pagination {
			display: flex;
			justify-content: center;
			align-items: center;

			.button {
				border: none;
				background: none;
				cursor: pointer;
				padding: 16px;
				color: #ce332e;
				font-size: toRem(14px);
				@include font-type(1);
				@include transition;

				&[disabled] {
					color: #d1d1d1;
				}
			}

			.page-numbers {
				display: flex;
				align-items: center;

				.page-number {
					padding: 8px 10px;
					color: #ce332e;
					border-radius: 4px;
					font-size: toRem(14px);
					@include transition;
					@include font-type(1);
					cursor: pointer;

					&.active {
						background: #ce332e;
						color: white;
					}
				}
			}
		}
	}
}

.badge-wrapper {
	display: flex;
	flex-direction: column;

	.badge-status {
		display: inline-block;
		padding: 8px 16px;
		border-radius: 15px;
		border: 1px solid;
		text-align: center;
		font-size: toRem(9px);
		letter-spacing: 0.58px;
		text-transform: uppercase;
		inline-size: fit-content;
		// align-self: center;

		&.big {
			align-content: center;
			padding: 16px 46px;
			border-radius: 21px;
		}

		&.pending {
			color: #977c30;
			background: #efd897;
			border: #efd897;
		}

		&.refused {
			color: #979e97;
			background: #d1d1d1;
			border: #d1d1d1;
		}

		&.approved {
			color: #859b2c;
			background: #d3e48d;
			border: #d3e48d;
		}

		&.informative {
			color: #004b7c;
			background: #00ccff;
			border: #00ccff;
		}

		&.sent-to-sac {
			color: #7e3b00;
			background: #f79239;
			border: #f79239;
		}
		&.not-sent-to-sac {
			color: #585c58;
			background: #d1d1d1;
			border: #d1d1d1;
		}
	}

	.date {
		@include font-type(2);
		font-size: toRem(9px);
		line-height: toRem(14px);
		letter-spacing: toRem(0.2px);
		margin-top: 4px;

		&.refused {
			color: #979e97;
		}

		&.approved {
			color: #859b2c;
		}
	}
}

.edit {
	@extend %link-button;
}

.empty-table {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 250px;

	.text {
		color: #d1d1d1;
		text-transform: uppercase;
		font-size: toRem(12px);
		@include font-type(1);
	}
}

.file-text-icon {
	cursor: pointer;
	align-self: center;
	height: 20px;
	width: 20px;
}

.trash-icon {
	cursor: pointer;
	align-self: center;
	height: 20px;
	width: 20px;
}

.time-picker {
	border-radius: 3px;
	border-style: solid;
	border-width: 1px;
	border-color: #eeeeee;
}

.resale-params-page {
	@extend .resale-params-page;

	.resale-params-table-wrapper {
		@extend .resale-table-wrapper;
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	.resale-params-table {
		@extend .resale-table;

		thead {
			@include d(sm) {
				display: none;
			}

			tr {
				color: $red;

				th {
					border-bottom: 0px;
					color: $red;
				}
			}
		}

		tbody {
			border: 0px;
		}
	}

	.col-4 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.location {
		display: flex;
		flex-direction: row;
		align-items: center;

		.chevron-right-icon {
			margin-right: 4px;
		}

		.location-text {
			@include font-type(1);
			color: #5d615d;
			font-size: 14px;
			margin-right: 4px;
			text-align: center;
		}
	}

	.always-row {
		@extend .row;

		@include d(sm) {
			flex-direction: row;
			justify-content: flex-start;
		}
	}

	.title-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 0px;

		.text-container {
			display: flex;
			flex-direction: column;

			.resale-title {
				@include font-type(1);
				color: #5d615d;
				font-size: 24px;
			}

			.resale-code {
				margin-top: 8px;
				@include font-type(1);
				font-size: 14px;
				color: $red;
			}

			.search-input {
				margin-top: 32px;
			}
		}
	}

	.red-button-container {
		display: flex;
		justify-content: flex-end;

		@include d(sm) {
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
	}

	.arrow-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.back-arrow-wrapper {
		@extend %link-button;
		display: flex;
		flex-direction: row;
		margin-top: 16px;
		align-self: flex-start;
		align-items: center;
		float: left;
		text-decoration: none;

		.back-arrow {
			width: 16px;
			height: 16px;
			margin-right: 4px;
		}

		p {
			@include font-type(2);
			color: $red;
			font-size: toRem(12px);
		}
	}

	.description {
		font-size: 0.825rem;
		color: $mediumGrey;
		padding-top: 4px;
	}
}

.delete-param-confirmation-modal {
	max-width: 427px;
	max-height: 296px;
	color: #5d615d;
	font-size: 1rem;
	line-height: 1.25rem;

	.modal-title {
		@include font-type(1);
		color: #5d615d;
		font-size: toRem(24px);
		margin-bottom: toRem(8px);
	}

	.param-name {
		@include font-type(1);
		color: $red;
		font-size: toRem(14px);
		margin-bottom: toRem(32px);
	}

	.confirm-text {
		@include font-type(1);
		color: #5d615d;
		font-size: toRem(16px);
		margin-bottom: toRem(48px);
	}

	.buttons-wrapper {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		text-align: center;
		width: 100%;
		// margin: 0 80px;
	}

	.transparent-button {
		text-align: center;
		margin-right: 16px;
	}

	.dark-button {
		@extend .default-shadow;
		background-color: #5d615d;
		text-align: center;
	}

	.close-button {
		position: relative;
		float: right;
		top: -35px;
	}
}

.clock-icon {
	height: auto;
	width: 22px;
	margin: 0px 6px 12px 0px;
}

.time-modal {
	.MuiToolbar-root {
		color: $red;
	}
}

.hide {
	opacity: 0;
}

.m-button {
	@include transition;
	background: #ffffff;
	border-radius: 4px;
	border: none;
	cursor: pointer;
	max-height: 41px;
	padding: 14px 40px;

	&__label {
		@include font-type(2);
		color: $red;
		font-size: 0.625rem;
		letter-spacing: 0.64px;
		text-transform: uppercase;

		&_white {
			color: white;
		}
	}

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		background: #d1d1d1;
		color: #5d615d;
		opacity: 1;
		cursor: not-allowed;
	}
}

.sac-page {
	background: #f9f9f9;
	min-height: 100vh;

	.headline {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: auto;
		margin: 60px 0 0 0;
	}

	.title {
		font-size: toRem(24px);
		color: #5d615d;
		@include font-type(1);
	}

	.title-user {
		font-size: toRem(24px);
		color: #5d615d;
		@include font-type(1);
	}
}

.register-product-modal {
	width: 100%;
	color: #5d615d;

	.no-padding {
		padding: 0;
	}

	.button-wrapper {
		text-align: center;
		width: 100%;

		flex-direction: row;

		* {
			margin: 0 8px 8px 0;
		}

		:last-child {
			margin: 0 0 8px 0;
		}
	}

	.close-button {
		position: relative;
		float: right;
		top: -35px;
	}

	.title {
		font-size: toRem(24px);
		line-height: toRem(32px);
		letter-spacing: toRem(0px);
	}

	.sub-title {
		text-align: left;
		font-size: toRem(14px);
		line-height: toRem(20px);
		letter-spacing: toRem(0.22px);
		padding: 0 0 8px 0;
		border-bottom: 1px solid #5d615d;
	}

	.image-uploader {
		display: flex;
		flex-direction: row;
		margin: 0 15%;
		// justify-content: space-around;
		justify-content: center;

		.image-wrapper {
			margin: 0 0 0 0;
			width: 190px;

			:last-child {
				margin: 0;
			}
		}

		.image {
			height: 166px;
			width: 166px;
		}

		.red-button {
			border-radius: 12px;
			height: fit-content;
			left: -30px;
			margin: 0;
			padding: 4px 8px;
			position: relative;
			top: -65px;
		}

		.placeholder-wrapper {
			width: 190px;
			margin: 0 0 0 0;

			&:last-child {
				margin: 0;
			}
		}

		.placeholder {
			background-color: #f1f1f1;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 166px;
			width: 166px;

			.placeholder-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			&:hover {
				background-color: #d4d4d4;
			}

			&__icon {
				margin-bottom: 4px;
			}

			&__text {
				@include font-type(2);
				color: $red;
				font-size: toRem(12px);
			}
		}
	}

	.input {
		@extend .input-secondary;
		margin-bottom: 16px;
		input {
			background: white;
		}
	}

	.input-row {
		flex-direction: row;
		display: flex;

		* {
			margin: 0 8px 8px 0;
		}

		:last-child {
			margin: 0 0 8px 0;
		}
	}

	.checkbox-content {
		border-bottom: 1px solid #d1d1d1;
		margin-bottom: 16px;
		padding: 8px 0;
		display: flex;
		align-items: center;

		.materialCustom {
			background: #fff;
			border: 1px solid #d1d1d1;
		}

		.labelMaterial {
			font-size: 14px;
			margin-left: 6px;
			cursor: pointer;
		}
	}
	.react-select {
		.react-select__control {
			background: #f9f9f929;
			border: solid 1px #d1d1d1;
			border-radius: 4px;
			margin: 0 0 16px 0;

			.react-select__indicator {
				color: $red;
			}

			.react-select__placeholder {
				color: $grey;
				font-size: 0.75em;
				letter-spacing: 0.3px;
				text-transform: uppercase;

				cursor: text;
				@include font-type(2);
				@include transition;
			}

			.react-select__single-value {
				color: #5d615d;
				@include transition;
			}

			.react-select__input {
				color: #5d615d;
				@include transition;
			}
		}
	}
}

.input-tag-container {
	background: white;
	border: 1px solid #d6d6d6;
	border-radius: 3px;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	padding: 5px 5px;
	position: relative;

	.input-tag-list {
		display: inline-flex;
		flex-wrap: wrap;

		margin: 0;
		padding: 0;
		width: 100%;

		.input-tag-list-item {
			align-items: center;
			background: $red;
			border-radius: 20px;
			color: white;
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			font-size: 14px;
			font-weight: 300;
			justify-content: space-between;
			list-style: none;
			margin-bottom: 5px;
			margin-right: 5px;
			max-height: 20px;
			padding: 6px 12px;

			.input-tag-button {
				align-items: center;
				background: transparent;
				border-radius: 50%;
				border: none;
				color: white;
				cursor: pointer;
				display: flex;
				font-size: 24px;
				height: 15px;
				justify-content: center;
				line-height: 0;
				margin-left: 12px;
				padding: 0;
				transform: rotate(45deg);
				width: 15px;

				&:hover {
					opacity: 0.5;
				}
			}
		}

		.input-tag-input-container {
			background: none;
			list-style: none;
			flex-grow: 1;
			padding: 0;

			.input-tag-input {
				border: none;
				width: 100%;
				padding-top: 6px;
				padding-bottom: 60px;
			}
		}
	}
}

.checkbox-param-input {
	color: $darkGrey;
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	margin-top: 12px;

	label {
		color: $darkGrey;
		margin-bottom: 0.5rem;
	}

	.error-message {
		display: flex;
		flex-direction: row;
		padding-left: 2px;

		img {
			height: 16px;
			width: 16px;
			margin-right: 4px;
		}

		p {
			color: var(--gp-palette-error);
			font-size: 0.75rem;
			font-weight: 500;
			line-height: 1.25rem;
		}
	}
}

.cancellation-requests {
	background: #f9f9f9;
	min-height: 100vh;

	.title {
		font-size: toRem(24px);
		color: #5d615d;
		margin-top: 64px;
		@include font-type(1);
	}

	.title-user {
		font-size: toRem(24px);
		color: #5d615d;
		@include font-type(1);
	}
}

.cancellation-modal {
	color: #5d615d;
	font-size: 0.875rem;
	line-height: 1.25rem;
	display: flex;
	flex-direction: column;

	.modal-section-title {
		width: 100%;
		font-size: toRem(14px);
		color: #5d615d;
		@include font-type(1);
		border-bottom: 1px solid #e6e6e6;
		margin-bottom: 16px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.modal-title {
			font-size: toRem(24px);
			color: #5d615d;
			@include font-type(1);
		}

		margin-bottom: 8px;
	}

	.order-number {
		display: inline-block;
		margin-bottom: 32px;
		color: $red;
		font-size: toRem(14px);
		@include font-type(1);
	}

	.timeline-container {
		margin-bottom: 64px;
	}

	.slider-container {
		margin-bottom: 64px;

		.slider-wrapper {
			padding: 0 28px;
			display: flex;
			align-items: center;
		}

		.steps {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.step {
				color: $red;
				text-transform: uppercase;
				font-size: toRem(12px);
				@include font-type(1);
			}
		}
	}

	%default-info {
		background: white;
		border-radius: 4px;
		padding: 32px 24px;
		margin-bottom: 16px;
	}

	%default-wrapper {
		flex: 1;
		min-width: 260px;
	}

	.info {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		margin: 0 0 35px 0;

		.order-details-wrapper {
			@extend %default-wrapper;
			margin-right: 8px;

			@include d(xs) {
				margin-right: 0;
				margin-left: 0;
			}

			.order-details {
				height: 85%;
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				@extend %default-info;
			}
		}

		.other-data-wrapper {
			@extend %default-wrapper;
			margin-left: 8px;

			@include d(xs) {
				margin-right: 0;
				margin-left: 0;
			}

			.other-data {
				height: 85%;
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				@extend %default-info;
			}
		}

		.item {
			display: flex;
			// align-items: center;

			&.big-space {
				margin-bottom: 16px;
			}

			.name {
				color: #5d615d;

				font-size: toRem(12px);
				@include font-type(1);
				margin-right: 4px;
			}

			.value {
				color: #979e97;

				font-size: toRem(12px);
				@include font-type(1);
			}
		}
	}
}

.hide {
	&_display {
		display: none;
	}

	&_opacity {
		opacity: 0;
	}
}

.resale-products-page {
	@extend .resale-params-page;

	.location {
		justify-content: space-between;
	}

	.white-button {
		margin-right: 16px;
	}
}

.resale-products-table-container {
	position: relative;
}

.placeholder-resale-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: toRem(96px);

	.placeholder-resale-text {
		@include font-type(1);
		color: $red;
		font-size: 16px;
		margin-top: 16px;
	}
}

.table-empty-message {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 32px;

	h1 {
		color: #5d615d;
		text-transform: uppercase;
		font-size: toRem(12px);
	}
}

.modalContent {
	text-align: end;

	img {
		width: 15px;
		margin-bottom: 30px;
	}
}

// TODO: Nikolas fix classes names
.CloseButton {
	cursor: pointer;
}

.backgroundKnowMore {
	background: transparent;
	border-color: transparent;
	color: $red;
	cursor: pointer;
}

.pcontent {
	max-width: 600px;
	line-height: 24px;
	text-align: left;

	.title {
		font-weight: bold;
		text-align: center;
		margin-bottom: 1rem;
	}

	.list {
		padding: 0 1rem;
		margin-bottom: 1rem;

		ul {
			padding: 0 3rem;
		}

		li {
			list-style-type: disc;
		}
	}

	p {
		margin-bottom: 1rem;
	}
}

.input-filter {
	width: 320px;
}

.pTerm1 {
	font-size: 10px;
	width: 100%;
	color: #5d615d;
	margin-left: 8px;
	text-transform: uppercase;
}

.pTerm2 {
	font-size: 10px;
	width: 100%;
	color: #5d615d;
	margin-left: 8px;
	text-transform: uppercase;
}

.CheckBoxItens li {
	margin-top: 8px;
}

.search-wrapper {
	display: flex;
	align-items: center;
}

.search-input-wrapper {
	margin-top: 32px;
}

.cart-payment-total-table {
	margin-top: 32px;

	table {
		width: 100%;
		thead {
			tr {
				th {
					padding: 8px 16px;
					text-align: left;
					color: #272927;
					font-size: toRem(14px);
					@include font-type(3);
				}
			}
		}

		tbody {
			tr {
				vertical-align: middle;
				background: white;
				td {
					text-align: left;
					color: #5d615d;
					font-size: toRem(14px);
					@include font-type(1);
					padding: 26px 16px;
				}
			}
		}
	}
}

.select-filter {
	width: 100%;
	position: relative;

	div div {
		// width: 100%;

		*.css-8atqhb {
			display: flex;
			flex-direction: row;
		}

		*.css-1hwfws3 {
			width: calc(90% - 24px);
			margin: 0;
			padding: 0;
		}

		*.css-151xaom-placeholder {
			font-size: 14px;
		}

		*.css-1wy0on6 {
			width: 24px;
		}
	}
}

.multiselect-label {
	font-size: 10px;
	font-weight: bold;
	// ${fontType(2)};
	position: absolute;
	margin-left: 14px;
	margin-top: 15px;
	color: var(--gp-palette-black);
	text-transform: uppercase;
	z-index: 1;
}

.combo-card-container {
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 250px;
	// width: 276px;

	&.this-combo-is-in-cart {
		border: 1px solid var(--gp-palette-primary-main);
	}

	.combo-card-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;

		.combo-card-wrapper-image {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			height: 95px;

			img {
				cursor: pointer;
				height: 100%;
			}
		}

		.combo-card-title {
			color: #5d615d;
			font-size: 14px;
			margin-top: 8px;
		}

		.combo-card-price-action {
			margin-top: 12px;
			display: flex;
			justify-content: space-between;

			.combo-card-price-action-value-title {
				color: #979e97;
				font-size: 12px;
			}

			.combo-card-price-action-data {
				color: #5d615d;
				font-size: 14px;
			}
		}
	}
}

.secondary-button {
	background: none;
	border: 1px solid #ce332e;
	border-radius: 4px;
	color: #ce332e;
	font-size: 10px;
	width: 139px;
	height: 33px;
	cursor: pointer;
}

.offer-chip {
	align-self: flex-start;
	padding: 3px 8px;
	border-radius: 10px;
	background-color: #e31b1b;
	font-size: 0.75rem;
	text-transform: uppercase;
	color: #ffffff;
}

.combo-offer-container {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.combo-min-amount {
	color: #ce332e;
	font-weight: bold;
	font-size: 12px;
	text-decoration: line-through;
}

.combo-price-action {
	color: #ce332e;
	font-weight: bold;
	font-size: 12px;
}

.combo-price-action-subtitle {
	color: #979e97;
	font-weight: bold;
	font-size: 12px;
}

.combo-price-per-unit {
	color: #979e97;
	font-weight: lighter;
	font-size: 12px;
}

.combo-list-page {
	@extend .page;

	.placeholder-wrapper {
		padding: 64px;
		display: flex;
		justify-content: center;
		align-items: center;

		.placeholder-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			h1 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 16px;
				color: #5d615d;
				margin-bottom: 8px;
			}

			h2 {
				width: 246px;
				text-align: center;
				@include font-type(1);
				font-size: 10px;
				color: #979e97;
				margin-bottom: 32px;
			}

			.gp-logo-wrapper {
				margin: 24px;

				.gp-logo {
					width: 124px;
					height: auto;
				}
			}

			.button {
				@extend .red-button;
			}
		}
	}

	.combo-page-title {
		margin-top: 20px;

		h2 {
			font-size: 0.875em;
			color: #5d615d;
			@include font-type(1);
			padding: 8px 0;
		}

		border-bottom: 1px solid #eeeeee;
	}

	.combo-list-content-wrapper {
		margin-top: 20px;
	}
}

.combo-filters {
	display: flex;
	justify-content: space-between;
	margin-top: 48px;

	@extend .col-12;

	.input {
		max-width: 260px;
		@extend .input-search;
	}

	.filters-wrapper {
		display: flex;
		gap: 8px;

		.miniable-input {
			@extend .select-small;
			min-width: 132px;
		}

		.maxalbe-input {
			@extend .select-small;
			min-width: 212px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;

		.filters-wrapper {
			flex-direction: column;

			div + div {
				margin-left: 0px;
				margin-top: 8px;
			}
		}

		.input {
			margin-top: 8px;
			max-width: 100%;
		}
	}
}

.value-table {
	display: flex;
	align-items: center;

	div {
		display: flex;
	}

	.prices-wrappers {
		min-width: 60px;
		display: flex;
		flex-direction: column;

		.initial-price {
			font-size: 12px;
			color: #ffa5a5;
			text-decoration: line-through;
		}

		.value-with-price {
			margin-top: 2px;
			color: #ce332e;
			font-size: 14px;
		}
	}

	.descont-badget-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			background: #ffd6d6;
			color: #ce332e;
			border-radius: 10px;
			font-size: 10px;
			padding: 3px 8px;
			height: 19px;
			margin-left: 10px;
		}
	}
}

.activation-code {
	display: flex;
	flex-direction: column;

	.activation-code-subtitle {
		font-size: 1.125rem;
		letter-spacing: 0.29px;
		color: #ffffff;
		margin-bottom: 64px;
		@include font-type(1);
	}

	.input {
		@extend .input-primary;
		align-self: center;
		margin-bottom: 48px;
		max-width: 360px;
	}

	.button {
		@extend .button-primary;
		align-self: center;
	}

	.submit-activation-code {
		@extend .button-primary;
		margin-bottom: 16px;
	}
}

.removed-items-info-message {
	span {
		color: #979e97;
		font-size: 10px;
		margin-left: 8px;
	}

	padding-left: 16px;
	margin-bottom: -40px;
}

.select-group,
.select-sales-organization {
	.react-select__value-container.react-select__value-container--has-value.css-86rg1c-ValueContainer {
		margin-top: 10px;
	}

	.react-select__value-container.react-select__value-container--has-value.css-tntsk8 {
		margin-top: 10px;
	}

	.react-select__value-container.css-86rg1c-ValueContainer {
		margin-top: 10px;
	}

	.react-select__value-container.css-tntsk8 {
		margin-top: 10px;
	}
}

.reset-button-style {
	background: none;
	border: none;
	margin: none;
	padding: none;
	cursor: pointer;
}

.hr-line {
	.row {
		hr {
			border: 1px solid #e0e0e0;
			width: 100vw;
		}
	}
}

.hide-block {
	display: none;
}

.adress-select-disabled {
	input:disabled {
		color: var(--gp-palette-black);
	}
}

// Buttons
.gp-button {
	padding: 14px 40px;
	border-radius: 4px;
	border: none;
	font-size: #{var(--gp-typography-size-textxs-size)}rem;
	text-transform: uppercase;
	@include font-type(2);
	cursor: pointer;
	@include transition;

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		background: #d1d1d1;
		color: #5d615d;
		opacity: 1;
		cursor: not-allowed;
	}

	&.primary {
		background-color: var(--gp-palette-primary-main);
		color: var(--gp-palette-primary-contrastText);
	}

	&.secondary {
		background-color: var(--gp-palette-secondary-main);
		color: var(--gp-palette-secondary-contrastText);
	}
}
