.show-password-button {
    position: absolute;
    right: 16px;
    top: 18px;

    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    padding: 5px;
}