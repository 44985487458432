@import '../../assets/scss/main.scss';

.custumerExclusionContainer {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 32px;
	justify-content: center;
	padding-bottom: 64px;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.title {
	@include font-type(1);
	color: #5d615d;
	font-size: 24px;
}

.divider {
	width: 100%;
	border-bottom: 1px solid #d1d1d1;
}
