@use 'sass:math';

$red: #CE332E;
$darkRed: #991717;
$orange: #e16036;
$success: #5ea715;
$error: #CE332E;
$darkGrey: #272927;
$mediumGrey: #5d615d;
$grey: #979e97;
$warningYellow: #ead637;
$darkYellow: #928204;
$baseFontSize: 16px;

// PLACEHOLDER
%flex {
	display: flex;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

%ellipsis {
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	max-width: 100%;
	overflow: hidden;
	line-height: 1rem;
}

%link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
}

@mixin transition($sec: 0.3s) {
	-webkit-transition: all $sec;
	-moz-transition: all $sec;
	transition: all $sec;
}

@mixin transitionIn($all: all, $sec: 0.3s) {
	-webkit-transition: $all $sec;
	-moz-transition: $all $sec;
	transition: $all $sec;
}

// MIXIN
@mixin font-type($type) {
	@if $type == 1 {
		font-style: normal;
		font-weight: 400;
	} @else if $type == 2 {
		font-style: normal;
		font-weight: 500;
	} @else if $type == 3 {
		font-style: normal;
		font-weight: 700;
	}
}

@mixin absoluteCenter {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin boxshadow($alpha: 0.15) {
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, $alpha);
	-moz-box-shadow: 0 2px 3px 0 0 rgba(0, 0, 0, $alpha);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, $alpha);
}

@mixin boxshadow_hover($alpha: 0.3) {
	-webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, $alpha);
	-moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, $alpha);
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, $alpha);
}

@mixin boxshadow_default($alpha: 0.16) {
	-webkit-box-shadow: 0 10px 35px 0 rgba(0, 0, 0, $alpha);
	-moz-box-shadow: 0 10px 35px 0 rgba(0, 0, 0, $alpha);
	box-shadow: 0 10px 35px 0 rgba(0, 0, 0, $alpha);
}

@mixin animation($name, $duration) {
	-webkit-animation: $name $duration;
	animation: $name $duration;
}

@keyframes rotate {
	0% {
		transform: rotate3d(0, 0, 0, 0deg);
	}

	100% {
		transform: rotate3d(0, 0, 1, -360deg);
	}
}

@function toRem($size) {
	@return math.div($size, $baseFontSize) * 1rem;
}
